import solarSoulPoster from '#/static/images/mockOvp/solar_soul_poster.jpg';
import solarSoulPortrait from '#/static/images/mockOvp/solar_soul_portrait.png';
import chaosCircuitPoster from '#/static/images/mockOvp/chaos_circuit_poster.jpg';
import chaosCircuitPortrait from '#/static/images/mockOvp/chaos_circuit_portrait.png';
import silentGridPoster from '#/static/images/mockOvp/silent_grid_poster.jpg';
import silentGridPortrait from '#/static/images/mockOvp/silent_grid_portrait.png';
import whiskersOfWonderPoster from '#/static/images/mockOvp/whiskers_of_wonder_poster.jpg';
import whiskersOfWonderPortrait from '#/static/images/mockOvp/whiskers_of_wonder_portrait.png';
import alive2024Poster from '#/static/images/mockOvp/alive_2024_poster.jpg';
import alive2024Portrait from '#/static/images/mockOvp/alive_2024_portrait.png';
import voiceOfIbcPoster from '#/static/images/mockOvp/voice_of_ibc_poster.jpg';
import voiceOfIbcPortrait from '#/static/images/mockOvp/voice_of_ibc_portrait.png';
import internationalBroadcastingInvestigationPoster from '#/static/images/mockOvp/international_broadcasting_investigation_poster.jpg';
import internationalBroadcastingInvestigationPortrait from '#/static/images/mockOvp/international_broadcasting_investigation_portrait.png';
import pedalToUtopiaInvestigationPoster from '#/static/images/mockOvp/pedal_to_utopia_investigation_poster.jpg';
import pedalToUtopiaInvestigationPortrait from '#/static/images/mockOvp/pedal_to_utopia_investigation_portrait.png';
import nectarsWhisperInvestigationPoster from '#/static/images/mockOvp/nectar_s_whisper_investigation_poster.jpg';
import nectarsWhisperInvestigationPortrait from '#/static/images/mockOvp/nectar_s_whisper_investigation_portrait.png';
import gaiasAwakeningPoster from '#/static/images/mockOvp/gaia_s_awakening_poster.jpg';
import gaiasAwakeningPortrait from '#/static/images/mockOvp/gaia_s_awakening_portrait.png';
import theLastBroadcastPoster from '#/static/images/mockOvp/the_last_broadcast_poster.jpg';
import theLastBroadcastPortrait from '#/static/images/mockOvp/the_last_broadcast_portrait.png';
import theLastHorizonPoster from '#/static/images/mockOvp/the_last_horizon_poster.png';
import theLastHorizonPortrait from '#/static/images/mockOvp/the_last_horizon_portrait.png';
import sunflowerDreamsPoster from '#/static/images/mockOvp/sunflower_dreams_poster.jpg';
import sunflowerDreamsPortrait from '#/static/images/mockOvp/sunflower_dreams_portrait.png';
import vpOfDolphinsPoster from '#/static/images/mockOvp/vp_of_dolphins_poster.jpg';
import vpOfDolphinsPortrait from '#/static/images/mockOvp/vp_of_dolphins_portrait.jpg';
import overrideAssets from '../mockOvp/ecoflow/assets.json';

// gaia / soul / whisker
export const HERO_CAROUSEL_IDS = ['361743', '239568', '277217'] as const;

export const BIG_BUCK_BUNNY_MANIFEST =
  'https://dash.akamaized.net/akamai/bbb_30fps/bbb_30fps.mpd';
export const TEARS_OF_STEEL_MANIFEST =
  'https://test.quanteec.com/contents/encodings/vod/0d432184-f060-44d7-746c-7561-6665-64-b835-275323a83f73d/mpd.mpd';
export const AVC_ENCODED_MANIFEST_SDR =
  'https://d27vtz3dv6w4gx.cloudfront.net/7da7568b-7154-410b-908f-d5b9c4b15b68/MeridianSDR/stream.mpd';
export const HEVC_ENCODED_MANIFEST_HDR =
  'https://d27vtz3dv6w4gx.cloudfront.net/2aa36f75-9054-4e4a-b06e-dbc7eec73380/MeridianSDR/stream.mpd';

export const MANIFEST_MAP = {
  [HERO_CAROUSEL_IDS[0]]: AVC_ENCODED_MANIFEST_SDR,
  [HERO_CAROUSEL_IDS[1]]: BIG_BUCK_BUNNY_MANIFEST,
  [HERO_CAROUSEL_IDS[2]]: TEARS_OF_STEEL_MANIFEST
};

export const MANIFEST_MAP_HDR = {
  [HERO_CAROUSEL_IDS[0]]: HEVC_ENCODED_MANIFEST_HDR,
  [HERO_CAROUSEL_IDS[1]]: BIG_BUCK_BUNNY_MANIFEST,
  [HERO_CAROUSEL_IDS[2]]: TEARS_OF_STEEL_MANIFEST
};

export const getDefaultStreamUrl = () => {
  return TEARS_OF_STEEL_MANIFEST;
};

export const getParsedStreamUrl = ({
  assetId,
  hdrEnabled
}: {
  assetId: string;
  hdrEnabled: boolean;
}) => {
  const fallback = hdrEnabled
    ? HEVC_ENCODED_MANIFEST_HDR
    : AVC_ENCODED_MANIFEST_SDR;
  const manifestMap = hdrEnabled ? MANIFEST_MAP_HDR : MANIFEST_MAP;
  return (manifestMap as any)[assetId] || fallback;
};

// IMPORTANT: for the video url mapping, see MANIFEST_MAP inside
// src\components\Player\Player.js

// backdrop, thumbnail, banner,
const IMAGES_MAP = new Map<string, any[]>();
// gaia s awak
IMAGES_MAP.set(HERO_CAROUSEL_IDS[0], [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: gaiasAwakeningPoster },
  { type: 'banner', url: gaiasAwakeningPoster },
  { type: 'poster', url: gaiasAwakeningPortrait }
]);
// Solar Soul
IMAGES_MAP.set(HERO_CAROUSEL_IDS[1], [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: solarSoulPoster },
  { type: 'banner', url: solarSoulPoster },
  { type: 'poster', url: solarSoulPortrait }
]);
// Whiskers of Wonder
IMAGES_MAP.set(HERO_CAROUSEL_IDS[2], [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: whiskersOfWonderPoster },
  { type: 'banner', url: whiskersOfWonderPoster },
  { type: 'poster', url: whiskersOfWonderPortrait }
]);
// the silent grid
IMAGES_MAP.set('181808', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: silentGridPoster },
  { type: 'banner', url: silentGridPoster },
  { type: 'poster', url: silentGridPortrait }
]);
// Alive 2024
IMAGES_MAP.set('1891', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: alive2024Poster },
  { type: 'banner', url: alive2024Poster },
  { type: 'poster', url: alive2024Portrait }
]);
// Voice of Ibc
IMAGES_MAP.set('140607', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: voiceOfIbcPoster },
  { type: 'banner', url: voiceOfIbcPoster },
  { type: 'poster', url: voiceOfIbcPortrait }
]);
// International Broadcasting Investigation
IMAGES_MAP.set('1893', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: internationalBroadcastingInvestigationPoster },
  { type: 'banner', url: internationalBroadcastingInvestigationPoster },
  { type: 'poster', url: internationalBroadcastingInvestigationPortrait }
]);
// Pedal to Utopia
IMAGES_MAP.set('1894', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: pedalToUtopiaInvestigationPoster },
  { type: 'banner', url: pedalToUtopiaInvestigationPoster },
  { type: 'poster', url: pedalToUtopiaInvestigationPortrait }
]);
// Nectar's Whisper
IMAGES_MAP.set('1895', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: nectarsWhisperInvestigationPoster },
  { type: 'banner', url: nectarsWhisperInvestigationPoster },
  { type: 'poster', url: nectarsWhisperInvestigationPortrait }
]);
// chaos circuit
IMAGES_MAP.set('11', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: chaosCircuitPoster },
  { type: 'banner', url: chaosCircuitPoster },
  { type: 'poster', url: chaosCircuitPortrait }
]);
// the last broadcast
IMAGES_MAP.set('181812', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: theLastBroadcastPoster },
  { type: 'banner', url: theLastBroadcastPoster },
  { type: 'poster', url: theLastBroadcastPortrait }
]);
// the last horizon
IMAGES_MAP.set('1892', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: theLastHorizonPoster },
  { type: 'banner', url: theLastHorizonPoster },
  { type: 'poster', url: theLastHorizonPortrait }
]);
// sunflower dreams
IMAGES_MAP.set('673309', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: sunflowerDreamsPoster },
  { type: 'banner', url: sunflowerDreamsPoster },
  { type: 'poster', url: sunflowerDreamsPortrait }
]);
IMAGES_MAP.set('824678', [
  // not sure why, but the first item has url undefined...
  { type: 'whatever', url: vpOfDolphinsPoster },
  { type: 'banner', url: vpOfDolphinsPoster },
  { type: 'poster', url: vpOfDolphinsPortrait }
]);

const OVERRIDE_IDS: string[] = [];

const OVERRIDE_DATA = {};

overrideAssets.forEach(ass => {
  OVERRIDE_IDS.push(ass.id);
  (OVERRIDE_DATA as any)[ass.id] = ass;
  if (IMAGES_MAP.has(ass.id)) {
    (OVERRIDE_DATA as any)[ass.id].images = IMAGES_MAP.get(ass.id) ?? [];
    (OVERRIDE_DATA as any)[ass.id].backgroundUrl =
      (OVERRIDE_DATA as any)[ass.id].images.filter(
        (im: any) => im.type === 'banner'
      )?.[0].url ?? '';
  }
});

export const parseOvpItem = (item: any) => {
  if (OVERRIDE_IDS.includes(item.id) && (OVERRIDE_DATA as any)[item.id]) {
    return {
      ...item,
      ...(OVERRIDE_DATA as any)[item.id]
    };
  }
  return { ...item };
};

export default {
  parseOvpItem
};
