// This is a header for TV only.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';

import useAppConfig from '#/hooks/useAppConfig';
import usePage from '#/hooks/usePage';
import useMenu from '#/hooks/useMenu';
import useI18n from '#/hooks/useI18n';
import useEcoProfile from '#/hooks/useEcoProfile';
import Menu from '#/components/Menu/Menu';
import { navIdMap } from '#/utils/navigationHelper';
import getResolution from '#/utils/getResolution';
import { R720p } from '#/theme/variables/breakpoints';
import LightThemeLogo from '#/static/images/ecoflow-logo-light.png';
import { EcoProfileType } from '#/interfaces/EcoProfile';

import styles from './tvHeader.scss';

const NAV_IDS = navIdMap.MENU.HEADER;

const nav = {
  container: {
    id: NAV_IDS.CONTAINER,
    forwardFocus: NAV_IDS.MENU,
    useLastFocus: true
  },
  menu: {
    id: NAV_IDS.MENU,
    parent: NAV_IDS.CONTAINER,
    forwardFocus: `${NAV_IDS.MENU_ITEM}0`,
    itemId: NAV_IDS.MENU_ITEM
  }
};

const TVHeader = ({ items }) => {
  const { getCurrentPageId } = usePage();
  const { config } = useAppConfig();
  const { currentLocale = {} } = useI18n();
  const { isMenuVisible } = useMenu();
  const { currentEcoProfile } = useEcoProfile();
  const [currentLogo, setCurrentLogo] = useState(config.appLogo);
  const focusCurrentPage = () => {
    const currentPageId = getCurrentPageId();
    currentPageId && focusManager.changeFocus(getCurrentPageId());
  };
  const { dir } = currentLocale;
  const { width } = getResolution();

  const containerClass = classNames(styles.container, {
    [styles.isVisible]: isMenuVisible
  });

  const imageClass = classNames(styles.img, styles[dir]);

  const customStyle = {
    ...(R720p >= width
      ? { paddingTop: '1rem', paddingBottom: '1rem' }
      : { padding: '1.5rem' })
  };

  useEffect(() => {
    focusManager.changeFocus(nav.menu.id);
  }, []);

  useEffect(() => {
    if (currentEcoProfile && config) {
      if (currentEcoProfile.darkUxEnabled) {
        setCurrentLogo(config.appLogo);
      } else {
        setCurrentLogo(LightThemeLogo);
      }
    }
  }, [currentEcoProfile, config]);

  return (
    <header dir={dir}>
      <FocusDiv
        style={customStyle}
        className={containerClass}
        nav={{
          ...nav.container,
          internal: {
            nextdown: focusCurrentPage
          }
        }}
        dir={dir}
      >
        {currentEcoProfile && !currentEcoProfile.darkUxEnabled && (
          <div className={styles.lightGradient} />
        )}
        <Menu
          items={items}
          imgLogo={currentLogo}
          nav={nav.menu}
          logoClassName={imageClass}
          isMeasureProfile={
            currentEcoProfile?.profileId === EcoProfileType.Measure
          }
        />
      </FocusDiv>
    </header>
  );
};

TVHeader.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default TVHeader;
