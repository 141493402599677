import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import FailedToLoad from '#/views/FailedToLoad/FailedToLoad';
import ContainerPage from '#/views/ContainerPage/ContainerPage';
import { PageInfoPropTypes } from '#/containers/PageLayout/PageLayout';

import useHistoryPush from '#/hooks/history/useHistoryPush';
import useAppError from '#/hooks/useAppError';
import { ROUTES } from '#/config/constants';
import useEcoProfile from '#/hooks/useEcoProfile';
import useAppConfig from '#/hooks/useAppConfig';

const MODE_DEV_ONBOARD = false;

const ModularPage = ({ pageInfo, ...props }) => {
  const { state } = useLocation();
  console.log(`[debug] state: `, state);
  const { addError, removeError } = useAppError();
  const historyPush = useHistoryPush();
  const {
    currentEcoProfile,
    onboardingDone,
    quickOnboarding
  } = useEcoProfile();
  const { config } = useAppConfig();

  const pageProps = useMemo(
    () => ({
      id: pageInfo.id
    }),
    [pageInfo]
  );

  useEffect(() => {
    if (MODE_DEV_ONBOARD && __DEVELOPMENT__) {
      if (MODE_DEV_ONBOARD && !onboardingDone && !currentEcoProfile) {
        // QUICK SETUP
        console.log(
          'MODE_DEV IS ACTIVE (similar to ?mode=dev on production, but for local dev)'
        );
        quickOnboarding();
      }

      return;
    }

    // check if onboarding was done
    // if not yet, brings user there
    if (!onboardingDone) {
      historyPush({ path: ROUTES.onboarding });
      return;
    }
    // if onboarding is done, check if an eco profile was selected
    // if not yet, bring user to profile selection
    if (!currentEcoProfile) {
      historyPush({ path: ROUTES.profileSelection });
    }
  }, [historyPush, currentEcoProfile, config, onboardingDone]);

  useEffect(() => {
    if (pageInfo.failedToLoad) {
      addError();
    } else {
      removeError();
    }
  }, [pageInfo.failedToLoad, addError, removeError]);

  if (pageInfo.failedToLoad) {
    return <FailedToLoad debugInfo={pageInfo.errorMessage} />;
  }

  if (!pageInfo.loaded) {
    return null;
  }

  return (
    <ContainerPage
      containers={pageInfo.containers}
      contextData={state}
      pageProps={pageProps}
      displayText={pageInfo.displayText}
      key={pageInfo.id}
      {...props}
    />
  );
};

ModularPage.propTypes = {
  pageInfo: PageInfoPropTypes.isRequired,
  containers: PropTypes.array,
  dispatch: PropTypes.func,
  displayText: PropTypes.string,
  errorMessage: PropTypes.string,
  failedToLoad: PropTypes.bool,
  loaded: PropTypes.bool,
  template: PropTypes.string
};

export default ModularPage;
