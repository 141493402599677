import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import { focusManager, withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { ITEM_TYPES } from '#/config/constants';
import Nav from '#/interfaces/Nav';
import { getTranslation } from '#/services/i18n';
import useMenu from '#/hooks/useMenu';
import useLatest from '#/hooks/useLatest';
import getImageByType from '#/utils/getImageByType';
import useEcoProfile from '#/hooks/useEcoProfile';
import style from './heroItem.scss';

interface HeroItem {
  data: any; // "any" until we review the data structure.
  nav: Nav;
  onClick: () => any | void;
  buttonText: string;
  width: number | string;
  height: number | string;
  isFocused: boolean;
}

const HeroItem = (heroItemProps: HeroItem) => {
  const {
    onClick,
    nav,
    data,
    buttonText: buttonTextProp,
    width: widthProp,
    height: heightProp,
    isFocused
  } = heroItemProps;
  const { trailerPlaying, setTrailerUrl } = useEcoProfile();
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);
  const [buttonText, setButtonText] = useState(buttonTextProp);
  const [poster, setPoster] = useState('');
  const { isMenuVisible } = useMenu();
  const isMenuVisibleRef = useLatest(isMenuVisible);
  const itemId = useRef('');

  const loadImage = useCallback(() => {
    if (!data.images?.length) {
      return;
    }
    const image = getImageByType(data.images, ITEM_TYPES.HeroBanner);
    setPoster(image);
  }, [data.images]);

  const loadButtonTranslation = useCallback(async () => {
    if (buttonText) {
      return;
    }

    const translation = getTranslation('moreDetails');
    setButtonText(translation);
  }, [buttonText]);

  useEffect(() => {
    loadImage();
    loadButtonTranslation();
  }, [loadButtonTranslation, loadImage]);

  const processFocusChange = useCallback(() => {
    const foc: string = focusManager.getCurrentFocus();
    const focusingNothingAndBeingFirstChild =
      foc === '' && nav.id.indexOf('-0') >= 0;
    const focusingThisHeroItem =
      foc === nav.id || focusingNothingAndBeingFirstChild;

    if (isMenuVisibleRef.current && focusingThisHeroItem) {
      setTrailerUrl(itemId.current);
    }
  }, []);

  useEffect(() => {
    if (data.id && data.title) {
      itemId.current = data.id;
      processFocusChange();
    }
  }, [data.id]);

  useEffect(() => {
    processFocusChange();
  }, [isFocused]);

  const backgroundClassName = `${style.background} ${
    nav.isFocused ? style.backgroundFocused : ''
  }`;

  return (
    <div style={{ width, height }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        {!trailerPlaying && (
          <ImageWithPreload src={poster} placeholderImgSize={500} />
        )}
        <div className={style.contentContainer}>
          <div className={style.title}>{data.displayText || data.title}</div>
          <div className={style.metadata}>{data.metadata || ''}</div>
          <div className={style.description}>{data.description || ''}</div>
          <RoundButton
            className={style.button}
            onClick={onClick}
            displayText={buttonText}
            nav={nav}
          />
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps: HeroItem, nextProps: HeroItem) => {
  const { data: prevData, nav: prevNav, buttonText: prevButtonText } =
    prevProps || {};
  const { data: nextData, nav: nextNav, buttonText: nextButtonText } =
    nextProps || {};

  return (
    prevButtonText === nextButtonText &&
    prevData.id === nextData.id &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright
  );
};

export default memo(withFocus(HeroItem), propsAreEqual);
