import ecoProfileImg from '#/static/images/profiles/eco.png';
import ultraProfileImg from '#/static/images/profiles/ultra.png';
import ecoflowProfileImg from '#/static/images/profiles/ecoflow_profile_app.png';
import measurefileImg from '#/static/images/profiles/measure_profile_app.png';

export const ECO_MODE_VALUES = [
  'AUTO',
  '360',
  '432',
  '540',
  '720',
  '1080',
  '1440',
  '2160'
];

const _GREEN_VIEW_VALUES: number[] = [];

for (let i = 0; i <= 100; i += 5) {
  _GREEN_VIEW_VALUES.push(i);
}

export const GREEN_VIEW_VALUES = _GREEN_VIEW_VALUES.map(val => {
  return `${val}%`;
});

export enum EcoProfileType {
  Performance = 'Performance',
  Eco = 'Eco',
  Standard = 'Standard',
  Measure = 'Measure'
}

export type EcoProfileTypeKeys = keyof typeof EcoProfileType;

export interface EcoProfile {
  profileId: EcoProfileTypeKeys;
  label: string;
  description: string;
  imageUrl: string;
  quanteecEnabled: boolean;
  bitmovinEcoModeEnabled: boolean;
  bitmovinEcoModeManualValue: string;
  greenViewAutoEnabled: boolean;
  greenViewManualEnabled: boolean;
  greenViewManualValue: string;
  hdrEnabled: boolean;
  darkUxEnabled: boolean;
  trailerEcoEnabled: boolean;
  audioOnlyEnabled?: boolean | null;
  // TODO: the absense of the following param(s) creates a crash in the player
  playbackUrl?: string | null;
  playbackLimit?: number | null;
}

export type EcoProfileApp = {
  label: string;
  description: string;
  profileId: EcoProfileTypeKeys;
  imageUrl: string;
};

export const EcoflowProfileApp: EcoProfileApp = {
  label: 'Ecoflow',
  description:
    'Demonstration consumer app: Designs and features targeted towards consumers',
  profileId: 'Standard',
  imageUrl: ecoflowProfileImg
};
export const MeasureProfileApp: EcoProfileApp = {
  label: 'Measure',
  description:
    'Technical measurement app: Designed and built to test individual eco features and settings throughout the app',
  profileId: 'Measure',
  imageUrl: measurefileImg
};

export const EcoOnlyProfile: EcoProfile = {
  profileId: 'Eco',
  label: 'Eco-Enabled',
  description:
    'This profile is designed for sustainability, featuring no autoplay on trailers and a dark-colored',
  imageUrl: ecoProfileImg,
  quanteecEnabled: true,
  bitmovinEcoModeEnabled: false,
  bitmovinEcoModeManualValue: ECO_MODE_VALUES[ECO_MODE_VALUES.length - 1],
  greenViewAutoEnabled: false,
  greenViewManualEnabled: true,
  greenViewManualValue: GREEN_VIEW_VALUES[6],
  hdrEnabled: false,
  darkUxEnabled: true,
  trailerEcoEnabled: false,
  audioOnlyEnabled: null,
  playbackUrl: null,
  playbackLimit: null
};

export type EcoProfileFieldsKeys = keyof typeof EcoOnlyProfile;

export const LABEL_MAP = new Map<EcoProfileFieldsKeys, string>();
LABEL_MAP.set('audioOnlyEnabled', 'Audio Only');
LABEL_MAP.set('darkUxEnabled', 'Dark & Light Mode');
LABEL_MAP.set('trailerEcoEnabled', 'Auto-playing Trailers');
LABEL_MAP.set('quanteecEnabled', 'Quanteec Peer-to-Peer Streaming');
LABEL_MAP.set('hdrEnabled', 'HDR');
LABEL_MAP.set('bitmovinEcoModeEnabled', 'Bitmovin Eco-mode');
LABEL_MAP.set('greenViewManualEnabled', 'Greenview (Manual)');
LABEL_MAP.set('greenViewAutoEnabled', 'Greenview (Auto)');

export const DESCRIPTIONS_MAP = new Map<EcoProfileFieldsKeys, string>();
DESCRIPTIONS_MAP.set(
  'darkUxEnabled',
  'Dark mode saves energy on OLED screens and reduces eye strain.'
);
DESCRIPTIONS_MAP.set(
  'trailerEcoEnabled',
  'Switching off auto-playing trailers can conserve energy and enhance viewing, if preferred.'
);

export const ENERGY_SAVINGS_MAP = new Map<
  EcoProfileFieldsKeys,
  { [index: string]: string }
>();
ENERGY_SAVINGS_MAP.set('darkUxEnabled', { ON: 'High', OFF: 'Low' });
ENERGY_SAVINGS_MAP.set('trailerEcoEnabled', { ON: 'Low', OFF: 'Moderate' });

export const getValuesSecondaryInput = (key: EcoProfileFieldsKeys) => {
  switch (key) {
    case 'bitmovinEcoModeEnabled':
      return ECO_MODE_VALUES;
    case 'greenViewManualEnabled':
      return GREEN_VIEW_VALUES;
    default:
      return [];
  }
};

export const initMapSecInputValues = () => {
  const mapp = new Map<EcoProfileFieldsKeys, string[]>();
  mapp.set(
    'bitmovinEcoModeEnabled',
    getValuesSecondaryInput('bitmovinEcoModeEnabled')
  );
  mapp.set(
    'greenViewManualEnabled',
    getValuesSecondaryInput('greenViewManualEnabled')
  );
  return mapp;
};

export const initDefaultSecInputValues = (ecoProfile: EcoProfile | null) => {
  const mapp = new Map<EcoProfileFieldsKeys, number>();
  if (ecoProfile) {
    const indexOfBitmovinEcoMode = ECO_MODE_VALUES.indexOf(
      ecoProfile.bitmovinEcoModeManualValue
    );
    const indexOfGreenview = GREEN_VIEW_VALUES.indexOf(
      ecoProfile.greenViewManualValue
    );
    mapp.set(
      'bitmovinEcoModeEnabled',
      indexOfBitmovinEcoMode >= 0 ? indexOfBitmovinEcoMode : 0
    );
    mapp.set(
      'greenViewManualEnabled',
      indexOfGreenview >= 0 ? indexOfGreenview : 0
    );
  }
  return mapp;
};

export const hasSecondaryInput = (key: EcoProfileFieldsKeys) => {
  switch (key) {
    case 'bitmovinEcoModeEnabled':
      return true;
    case 'greenViewManualEnabled':
      return true;
    default:
      return false;
  }
};

export const MeasureProfile: EcoProfile = {
  profileId: 'Measure',
  label: 'Measure',
  darkUxEnabled: true,
  trailerEcoEnabled: false,
  hdrEnabled: false,
  quanteecEnabled: false,
  bitmovinEcoModeEnabled: false,
  bitmovinEcoModeManualValue: ECO_MODE_VALUES[ECO_MODE_VALUES.length - 1],
  greenViewAutoEnabled: false,
  greenViewManualEnabled: false,
  greenViewManualValue: GREEN_VIEW_VALUES[GREEN_VIEW_VALUES.length - 1],
  description: 'TODO',
  imageUrl: '',
  audioOnlyEnabled: null,
  playbackUrl: null,
  playbackLimit: null
};

export const StandardProfile: EcoProfile = {
  ...EcoOnlyProfile,
  profileId: 'Standard',
  label: 'Standard',
  description: 'TODO',
  imageUrl: '',
  hdrEnabled: true,
  greenViewAutoEnabled: true,
  greenViewManualEnabled: false,
  greenViewManualValue: GREEN_VIEW_VALUES[GREEN_VIEW_VALUES.length - 1],
  quanteecEnabled: false
};

export const PerformanceProfile: EcoProfile = {
  profileId: 'Performance',
  label: 'High Energy',
  description:
    'This profile lacks sustainability features, with autoplay on trailers and a light-colored',
  imageUrl: ultraProfileImg,
  quanteecEnabled: false,
  bitmovinEcoModeEnabled: false,
  bitmovinEcoModeManualValue: ECO_MODE_VALUES[0],
  greenViewAutoEnabled: false,
  greenViewManualEnabled: false,
  greenViewManualValue: GREEN_VIEW_VALUES[GREEN_VIEW_VALUES.length - 1],
  hdrEnabled: true,
  darkUxEnabled: true,
  trailerEcoEnabled: true,
  audioOnlyEnabled: null,
  playbackLimit: null,
  playbackUrl: null
};

export const DefaultEcoProfiles = new Map<EcoProfileTypeKeys, EcoProfile>();
DefaultEcoProfiles.set('Eco', { ...EcoOnlyProfile });
DefaultEcoProfiles.set('Standard', { ...StandardProfile });
DefaultEcoProfiles.set('Performance', { ...PerformanceProfile });
DefaultEcoProfiles.set('Measure', { ...MeasureProfile });
