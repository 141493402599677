import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as BitmovinCore from 'bitmovin-player/modules/bitmovinplayer-core';
import classNames from 'classnames';
import { device } from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';

import { ID as webOsId } from '@accedo/xdk-device-lg-webos';
import { ID as tizenId } from '@accedo/xdk-device-samsung-tizen';

import {
  Player as BitmovinPlayer,
  PlayerEvent
} from 'bitmovin-player/modules/bitmovinplayer-core';
import EngineBitmovinModule from 'bitmovin-player/modules/bitmovinplayer-engine-bitmovin';
import MseRendererModule from 'bitmovin-player/modules/bitmovinplayer-mserenderer';
import HlsModule from 'bitmovin-player/modules/bitmovinplayer-hls';
import DashModule from 'bitmovin-player/modules/bitmovinplayer-dash';
import AbrModule from 'bitmovin-player/modules/bitmovinplayer-abr';
import XmlModule from 'bitmovin-player/modules/bitmovinplayer-xml';
import ContainerTSModule from 'bitmovin-player/modules/bitmovinplayer-container-ts';
import ContainerMp4Module from 'bitmovin-player/modules/bitmovinplayer-container-mp4';
import EngineNativeModule from 'bitmovin-player/modules/bitmovinplayer-engine-native';
import SubtitlesModule from 'bitmovin-player/modules/bitmovinplayer-subtitles';
import SubtitlesCEA608Module from 'bitmovin-player/modules/bitmovinplayer-subtitles-cea608';
import PolyfillModule from 'bitmovin-player/modules/bitmovinplayer-polyfill';

import useStateRef from '#/hooks/useStateRef';
import useEcoProfile from '#/hooks/useEcoProfile';
import useMenu from '#/hooks/useMenu';
import {
  getDefaultStreamUrl,
  getParsedStreamUrl
} from '#/providers/ovp/accedo/ovpParser';
import useEcoModal from '#/hooks/useEcoModal';
import defaultStyle from './playerTrailer.scss';
import { stripVideoAdaptationSets } from '../Player/utils';

type PlayerTrailerProps = {
  fullscreen?: boolean;
  detailsPage?: boolean;
  overlay?: boolean;
  onPlaybackStart?: () => void;
};

const PlayerTrailer = ({
  fullscreen = false,
  detailsPage = false,
  overlay = false,
  onPlaybackStart
}: PlayerTrailerProps) => {
  const [canStartPlayback, setCanStartPlayback] = useState(false);
  const [
    player,
    setPlayer,
    playerRef
  ] = useStateRef<BitmovinCore.PlayerAPI | null>(null);
  const xdkVideoRef = useRef();
  const playerDiv = useRef<HTMLDivElement | null>(null);
  const {
    currentEcoProfile,
    trailerUrl,
    trailerUrlRef,
    setTrailerPlaying,
    setTrailerUrl
  } = useEcoProfile();
  const { isMenuVisible } = useMenu();
  const lastTrailerUrlLoaded = useRef('');
  const lastTrailerUrl = useRef('');
  const { POP_UP_MODAL_NAV } = useEcoModal();
  // TODO: use trailer url here
  const playerSource = {
    dash: getDefaultStreamUrl(),
    poster: ''
  };

  const tweaksConfig = {
    file_protocol: true,
    app_id: 'com.accedo.ecoflow'
  };

  // TODO: remove this?
  const audioOnlyMode = false;
  const networkConfig = {
    preprocessHttpResponse: stripVideoAdaptationSets(audioOnlyMode)
  };

  const playerConfig: BitmovinCore.PlayerConfig = {
    // Retrieve this from Control configs object instead
    key: '878150bc-b69b-4ea5-abc4-cc54ccfca647',
    playback: {
      autoplay: true,
      muted: false
    },
    tweaks:
      device.platform === webOsId || device.platform === tizenId
        ? tweaksConfig
        : {},
    network: networkConfig,
    buffer: { audio: { backwardduration: 0 }, video: { backwardduration: 0 } }
  };

  const destroyPlayer = useCallback(() => {
    if (playerRef.current != null) {
      playerRef.current.destroy();
      setPlayer(null);
    }
  }, []);

  useEffect(() => {
    return () => {
      destroyPlayer();
    };
  }, []);

  const onVideoDidMount = useCallback(async playerInstance => {
    // videoRef.getVideo() will get the XDK media instance
    // and we are allowed to use XDK native functions
    xdkVideoRef.current = playerInstance;
  }, []);

  const startPlayback = async () => {
    try {
      if (playerRef.current) {
        playerSource.dash = getParsedStreamUrl({
          assetId: trailerUrlRef?.current ?? '',
          hdrEnabled: false
        });
        // TODO: we are manually disabling hdr!!!!!!!!!!!!!
        // it is a requirement from the ticket, but this will
        // actually change the overall settings...
        await playerRef.current.load(playerSource);
        await onVideoDidMount(playerRef.current);
        console.log('Successfully loaded source');
      }
    } catch (e) {
      console.log('Error while loading source', e);
    }
  };

  const unloadPlayer = async () => {
    player?.unload();
  };

  const setupPlayer = async () => {
    if (!playerDiv.current) {
      return;
    }

    BitmovinPlayer.addModule(EngineBitmovinModule);
    BitmovinPlayer.addModule(MseRendererModule);
    BitmovinPlayer.addModule(HlsModule);
    BitmovinPlayer.addModule(XmlModule);
    BitmovinPlayer.addModule(DashModule);
    BitmovinPlayer.addModule(AbrModule);
    BitmovinPlayer.addModule(ContainerTSModule);
    BitmovinPlayer.addModule(ContainerMp4Module);
    BitmovinPlayer.addModule(EngineNativeModule);
    BitmovinPlayer.addModule(SubtitlesModule);
    BitmovinPlayer.addModule(SubtitlesCEA608Module);
    BitmovinPlayer.addModule(PolyfillModule);

    switch (device.platform) {
      case webOsId: {
        const { default: DrmModule } = await import(
          'bitmovin-player/modules/bitmovinplayer-drm'
        );
        BitmovinPlayer.addModule(DrmModule);

        const { default: WebOSModule } = await import(
          'bitmovin-player/modules/bitmovinplayer-webos'
        );
        BitmovinPlayer.addModule(WebOSModule);
        break;
      }

      case tizenId: {
        const { default: DrmModule } = await import(
          'bitmovin-player/modules/bitmovinplayer-drm'
        );
        BitmovinPlayer.addModule(DrmModule);

        const { default: TizenModule } = await import(
          'bitmovin-player/modules/bitmovinplayer-tizen'
        );
        BitmovinPlayer.addModule(TizenModule);
        break;
      }

      default:
        break;
    }

    const playerInstance = new BitmovinPlayer(playerDiv.current, playerConfig);
    setPlayer(playerInstance);
    startPlayback();
  };

  const handleStartPlayback = useCallback(() => {
    if (!playerRef.current) {
      setupPlayer();
    } else {
      startPlayback();
    }
  }, []);

  const playerDivRefCallback = useCallback(node => {
    if (node !== null) {
      playerDiv.current = node;
    }
  }, []);

  useEffect(() => {
    const onTimeUpdate = () => {
      if (onPlaybackStart) {
        onPlaybackStart();
      }
      setTrailerPlaying(true);
      player?.off(PlayerEvent.TimeChanged, onTimeUpdate);
    };
    if (player && !!trailerUrl) {
      player?.on(PlayerEvent.TimeChanged, onTimeUpdate);
    }
    return () => {
      if (player) {
        player?.off(PlayerEvent.TimeChanged, onTimeUpdate);
      }
    };
  }, [player, trailerUrl, onPlaybackStart]);

  useEffect(() => {
    if (currentEcoProfile === null || !currentEcoProfile?.trailerEcoEnabled) {
      return;
    }

    if (canStartPlayback) {
      handleStartPlayback();
    } else {
      unloadPlayer();
    }
  }, [currentEcoProfile, canStartPlayback]);

  useEffect(() => {
    return () => {
      setTrailerPlaying(false);
      setTrailerUrl('');
      destroyPlayer();
    };
  }, []);

  useEffect(() => {
    if (!detailsPage && !isMenuVisible) {
      setTrailerUrl('');
    }
  }, [isMenuVisible, detailsPage]);

  // restoring the last trailer url for details page only,
  // - when the no CTA button is focused anymore, stop the playback
  // - resume the playback as soon as a CTA button is focused
  // the other scenario will be handled by their own component (e.g. HeroTile)
  useEffect(() => {
    const onFocusChange = (focusData: any) => {
      const focusingAnyCTAButton = focusData.currentFocus.indexOf('CTA_') >= 0;
      const focusingAnyPopupModal =
        focusData.currentFocus.indexOf(POP_UP_MODAL_NAV.action.id) >= 0 ||
        focusData.currentFocus.indexOf(POP_UP_MODAL_NAV.close.id) >= 0 ||
        focusData.currentFocus.indexOf(POP_UP_MODAL_NAV.leftToggle.id) >= 0 ||
        focusData.currentFocus.indexOf(POP_UP_MODAL_NAV.rightToggle.id) >= 0;

      if (
        focusingAnyCTAButton &&
        trailerUrl === '' &&
        lastTrailerUrl.current !== ''
      ) {
        setTrailerUrl(lastTrailerUrl.current);
      } else if (
        !focusingAnyCTAButton &&
        !focusingAnyPopupModal &&
        trailerUrl !== ''
      ) {
        lastTrailerUrl.current = trailerUrl;
        setTrailerUrl('');
      }
    };

    if (detailsPage) {
      focusManager.listenToFocusChanged(onFocusChange);
    }

    return () => {
      focusManager.unlistenToFocusChanged(onFocusChange);
    };
  }, [detailsPage, trailerUrl]);

  useEffect(() => {
    let timeoutPlayback = -1;

    if (currentEcoProfile === null || !currentEcoProfile?.trailerEcoEnabled) {
      return;
    }

    if (trailerUrl !== '') {
      if (trailerUrl !== lastTrailerUrlLoaded.current) {
        setTrailerPlaying(false);
        setCanStartPlayback(false);

        timeoutPlayback = window.setTimeout(() => {
          setCanStartPlayback(true);
        }, 2000);

        lastTrailerUrlLoaded.current = trailerUrl;
      }
    } else {
      setCanStartPlayback(false);
      lastTrailerUrlLoaded.current = '';
    }

    return () => {
      if (timeoutPlayback !== -1) {
        window.clearTimeout(timeoutPlayback);
      }
    };
  }, [currentEcoProfile, trailerUrl]);

  return (
    <div
      className={classNames(defaultStyle.playerTrailerContainer, {
        [defaultStyle.hide]: trailerUrl === ''
      })}
    >
      <div
        id="bitmovinPlayer"
        className={classNames(defaultStyle.bitmovinPlayer, {
          [defaultStyle.translated]: !fullscreen
        })}
        ref={playerDivRefCallback}
      />
      {overlay && <div className={defaultStyle.overlay} />}
    </div>
  );
};

export default PlayerTrailer;
