import React, { useEffect, useState, memo } from 'react';
import { getProtocolRelativePathUrl } from '#/utils/url';
import vw from '#/utils/vw';
import { Config } from '#/interfaces/Config';
import useAppConfig from '#/hooks/useAppConfig';
import style from './imageWithPreload.scss';

const DEFAULT_PLACEHOLDER_IMG_SIZE = 200;

interface ImageCustomStyle {
  height?: string;
  width?: string;
}

interface ImageWithPreloadProps {
  height?: string | number;
  width?: string | number;
  src: string;
  className?: any;
  alt?: string;
  viewAll?: boolean;
  placeholderImgSize?: number;
}

const ImageWithPreload = ({
  src: srcProp,
  className = '',
  alt = '',
  height,
  width,
  viewAll,
  placeholderImgSize
}: ImageWithPreloadProps) => {
  const [src, setSrc] = useState<string | undefined>();
  const [
    containerCustomStyle,
    setContainerCustomStyle
  ] = useState<ImageCustomStyle | null>(null);
  const { config = {} } = useAppConfig();
  const [imageHasBeenLoaded, setImageHasBeenLoaded] = useState(false);

  const handleSizeValue = (value: string | number | undefined) => {
    if (!value) {
      return '100%';
    }

    if (typeof value === 'number') {
      return `${value}px`;
    }

    return value;
  };

  useEffect(() => {
    if (!srcProp) {
      return;
    }

    const customStyleEdit: ImageCustomStyle = {};

    const heightS = handleSizeValue(height);
    customStyleEdit.height = heightS;

    const widthS = handleSizeValue(width);
    customStyleEdit.width = widthS;

    setContainerCustomStyle(customStyleEdit);
    setSrc(getProtocolRelativePathUrl(srcProp));
  }, [srcProp]);

  // TODO: maybe we need to improve how to detect which kind of img is
  // at the moment we get: 'undefined' for portrait tiles; '500' for hero
  const isPortrait = placeholderImgSize === undefined;
  const logoToUse = isPortrait
    ? (config as Config)?.appVerticalLogo
    : (config as Config)?.appLogo;
  let bgSize = placeholderImgSize
    ? vw(placeholderImgSize)
    : vw(DEFAULT_PLACEHOLDER_IMG_SIZE);
  if (isPortrait) {
    bgSize = '';
  }

  const placeholder = {
    backgroundImage: !viewAll ? `url(${logoToUse})` : 'url("")',
    backgroundSize: bgSize,
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  const backgroundPlaceholder = {
    backgroundSize: placeholderImgSize
      ? vw(placeholderImgSize)
      : vw(DEFAULT_PLACEHOLDER_IMG_SIZE),
    background:
      'radial-gradient(87.25% 121% at 91.3% 91.16%, #002229 44.74%, #000000 95.5%)'
  };

  const animationStyle = imageHasBeenLoaded
    ? style.imgLoaded
    : style.imgLoading;

  return (
    <div
      className={`${style.imgContainer} ${style.imgFallback}`}
      style={{ ...backgroundPlaceholder, ...containerCustomStyle }}
    >
      <div
        data-testid="imagepreloadcontainer"
        style={{ ...placeholder, ...containerCustomStyle }}
      >
        <div className={`${style.imgContent} ${animationStyle}`}>
          {src && (
            <img
              alt={alt}
              src={src}
              className={className}
              onLoad={() => {
                setImageHasBeenLoaded(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (
  prevProps: ImageWithPreloadProps,
  nextProps: ImageWithPreloadProps
) => {
  const { src: prevSrc } = prevProps || {};
  const { src: nextSrc, viewAll: nextViewAll } = nextProps || {};

  return prevSrc === nextSrc && !nextViewAll;
};

export default memo(ImageWithPreload, propsAreEqual);
