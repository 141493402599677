import { useCallback, useContext } from 'react';
import { EcoProfileContext } from '#/context/EcoProfileContext';

const useEcoProfile = () => {
  const {
    trailerUrl,
    trailerUrlRef,
    trailerPlaying,
    currentEcoProfile,
    onboardingDone,
    ecoProfileOptionsModalOpen,
    ecoProfileOptionsModalOpenRef,
    setEcoProfileOptionsModalOpen,
    smallEcoProfileOptionsModalOpen,
    smallEcoProfileOptionsModalOpenRef,
    setSmallEcoProfileOptionsModalOpen,
    setOnboardingDone,
    setTrailerPlaying,
    setTrailerUrl,
    loadDefaultEcoProfile,
    setCurrentEcoProfile,
    overrideCurrentEcoProfile,
    currentEcoProfileLoading
  } = useContext(EcoProfileContext);

  const quickOnboarding = useCallback(() => {
    setOnboardingDone();
    setCurrentEcoProfile('Measure');
  }, []);

  return {
    trailerUrl,
    trailerUrlRef,
    trailerPlaying,
    currentEcoProfile,
    onboardingDone,
    ecoProfileOptionsModalOpen,
    ecoProfileOptionsModalOpenRef,
    smallEcoProfileOptionsModalOpen,
    smallEcoProfileOptionsModalOpenRef,
    setSmallEcoProfileOptionsModalOpen,
    currentEcoProfileLoading,
    setEcoProfileOptionsModalOpen,
    setOnboardingDone,
    setTrailerPlaying,
    setTrailerUrl,
    loadDefaultEcoProfile,
    setCurrentEcoProfile,
    overrideCurrentEcoProfile,
    quickOnboarding
  };
};

export default useEcoProfile;
