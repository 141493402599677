import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/DynamicSizeContentGrid';
import InfoItem from '#/components/Item/InfoItem/InfoItem';
import useEcoModal from '#/hooks/useEcoModal';

import useI18n from '#/hooks/useI18n';

import infoBannerTheme from './infoBanner.scss';

const itemWidth = 1760;
let itemHeight = 826;

const InfoBannerShelf = ({ items, config = {}, nav = {}, ds }) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const [infoBannerData, setInfoBannerData] = useState(null);
  const { showModalOnClick } = useEcoModal();
  itemHeight = config?.query?.includes('data:') ? 690 : 826;

  useEffect(() => {
    const getInfoBannerData = async () => {
      const { data } = await ds.getData();
      const [bannerData] = data;
      setInfoBannerData(bannerData);
    };

    getInfoBannerData();
  }, []);

  return (
    <ContentGrid
      data={items}
      ds={ds}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      width={itemWidth}
      loop
      theme={infoBannerTheme}
      DisplayComponent={InfoItem}
      displayComponentProps={{ height: itemHeight }}
      onClick={() => {
        showModalOnClick(nav.id, infoBannerData?.learnMoreModalData);
      }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
    />
  );
};

InfoBannerShelf.propTypes = {
  ds: PropTypes.object.isRequired,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default InfoBannerShelf;
