import React, { useState } from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import useEcoModal from '#/hooks/useEcoModal';
import useEcoProfile from '#/hooks/useEcoProfile';
import PopUpDarkLogo from '#/static/images/popup-dark-logo.png';
import { EcoProfileType } from '#/interfaces/EcoProfile';
import style from './popUpModal.scss';
import { FocusRoundButton } from '../RoundButton/RoundButton';
import Modal from '../Modal/Modal';
import { ChevronUpIcon, InfoIcon } from '../Icons/Icons';

const PopUpModal = () => {
  const { currentEcoProfile } = useEcoProfile();
  const [showPopUp, togglePopUp] = useState(false);
  const [popUpModalData, setPopUpModalData] = useState({});
  const {
    POP_UP_MODAL_NAV,
    hidePopUpOnClick,
    setIsPopUpModalVisible,
    getNextEcoTip,
    getPreviousEcoTip
  } = useEcoModal({
    togglePopUp,
    setPopUpModalData
  });
  const {
    index,
    total,
    title,
    bodyText,
    actionButtonText,
    hideActionButtons
  } = popUpModalData;

  setIsPopUpModalVisible(showPopUp);

  return (
    showPopUp &&
    currentEcoProfile?.profileId !== EcoProfileType.Measure && (
      <Modal>
        <div className={style.background}>
          <div
            className={classNames(style.outerContainer, {
              [style.lightTheme]: !currentEcoProfile?.darkUxEnabled,
              [style.manualRotation]: !hideActionButtons
            })}
          >
            {hideActionButtons && (
              <div className={style.headerContainer}>
                <div
                  className={classNames(style.infoIcon, {
                    [style.lightTheme]: !currentEcoProfile?.darkUxEnabled
                  })}
                >
                  <InfoIcon />
                </div>
                <div className={style.logo}>
                  <img src={PopUpDarkLogo} alt="Pop Up Logo" />
                </div>
              </div>
            )}
            <div className={style.horizontalContainer}>
              {!hideActionButtons && (
                <FocusRoundButton
                  displayText={<ChevronUpIcon />}
                  className={style.leftToggleButton}
                  nav={POP_UP_MODAL_NAV.leftToggle}
                  onClick={() => {
                    getPreviousEcoTip(index);
                  }}
                />
              )}
              <div
                className={classNames(style.innerContainer, {
                  [style.audioOnly]: hideActionButtons,
                  [style.manualRotation]: !hideActionButtons
                })}
              >
                <div className={style.title}>{`🌱 ${title}`}</div>
                <div
                  className={classNames(style.bodyText, {
                    [style.audioOnly]: hideActionButtons
                  })}
                >
                  {bodyText}
                </div>
              </div>
              {!hideActionButtons && (
                <FocusRoundButton
                  displayText={<ChevronUpIcon />}
                  className={style.rightToggleButton}
                  nav={POP_UP_MODAL_NAV.rightToggle}
                  onClick={() => {
                    getNextEcoTip(index);
                  }}
                />
              )}
            </div>
            {!hideActionButtons && (
              <>
                <div className={style.tipsCounter}>
                  {index + 1} of {total}
                </div>
                <div className={style.buttonsContainer}>
                  <FocusRoundButton
                    displayText="Close"
                    className={style.closeButton}
                    nav={POP_UP_MODAL_NAV.close}
                    onClick={() => {
                      hidePopUpOnClick(false);
                    }}
                  />
                  <FocusRoundButton
                    displayText={actionButtonText || 'Learn More'}
                    className={style.actionButton}
                    nav={POP_UP_MODAL_NAV.action}
                    onClick={() => {
                      hidePopUpOnClick(true);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    )
  );
};

export default withFocus(PopUpModal);
