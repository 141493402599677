// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import { ProfileContext } from '#/context/ProfileContext';
import {
  SearchIcon,
  UserIcon,
  SettingsIcon,
  HomeIcon,
  MovieIcon,
  BookIcon,
  EcoSettingsIcon
} from '#/components/Icons/Icons';
import Avatar from '#/components/Avatar';
import style from './menuItem.scss';

interface MenuItemProps {
  onClick: any;
  data: any;
  nav: any;
  isMeasureProfile: boolean;
}

const icons = {
  search: () => <SearchIcon />,
  profile: () => <UserIcon />,
  settings: () => <SettingsIcon />,
  home: () => <HomeIcon />,
  movie: () => <MovieIcon />,
  book: () => <BookIcon />,
  ecoSettings: () => (
    <EcoSettingsIcon
      overrideStyle={{
        style: {
          stroke: 'none',
          strokeWidth: 1,
          fill: 'none',
          overflow: 'visible'
        }
      }}
    />
  )
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @param {Object} props.userFullname userFullname to be used in the avatar
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({ onClick, data, nav, isMeasureProfile }: MenuItemProps) => {
  const [content, setContent] = useState<any>(null);
  const [icon, setIcon] = useState<JSX.Element | null>(null);
  const [contentStyle, setContentStyle] = useState<string>('');
  const [iconStyle, setIconStyle] = useState<string>('');
  const { currentProfile } = useContext<any>(ProfileContext);

  const { pathname } = useLocation();

  const { isProfileItem, itemGroup, viaIconLabel } = data;

  const isIcon = useCallback(() => !!viaIconLabel, [viaIconLabel]);

  const isProfile = useCallback(() => isProfileItem, [isProfileItem]);

  const isSelected = useCallback(() => pathname === data.route, [
    pathname,
    data.route
  ]);

  const getIcon = (
    iconType: 'search' | 'profile' | 'settings',
    isMeasure: boolean
  ) => {
    if (!isMeasure && iconType === 'settings') {
      return icons.ecoSettings;
    }

    return icons[iconType];
  };

  useEffect(() => {
    if (isIcon() && getIcon(viaIconLabel, isMeasureProfile)) {
      setIcon(getIcon(viaIconLabel, isMeasureProfile));
      setIconStyle(style.icon);
    }

    if (data.itemGroup === 'primary') {
      setContent(data.displayText);
      setContentStyle(style.default);
    }
  }, [
    nav.isFocused,
    viaIconLabel,
    data.displayText,
    isProfileItem,
    currentProfile,
    isMeasureProfile
  ]);

  return (
    <div
      className={`${style.item} ${style[itemGroup]} ${
        nav.isFocused ? style.focused : ''
      } ${isSelected() ? style.selected : ''} ${
        !isMeasureProfile && viaIconLabel === 'settings' ? style.ecoProfile : ''
      }`}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <div className={data.itemGroup === 'primary' ? contentStyle : iconStyle}>
        <button onClick={onClick}>
          <div className={data.itemGroup === 'primary' ? iconStyle : ''}>
            {icon}
          </div>
          <div>{content}</div>
        </button>
      </div>
    </div>
  );
};

export default withFocus(MenuItem);
