import { HttpRequestType } from 'bitmovin-player/modules/bitmovinplayer-core';
import * as i18n from '#/services/i18n';
import {
  getNextEpisode,
  getPrevEpisode,
  getNextEpisodes,
  getPrevEpisodes
} from '#/services/ovp';
import AnalyticsAsset from '#/models/AnalyticsAsset';

const DEFAULT_FALLBACK =
  '//d1knl4odus8cue.cloudfront.net/bbb/bbb_sunflower_1080p_60fps_normal.mp4';

const STREAMS_TO_REPLACE = [
  'https://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4',
  'https://d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
  '//d1knl4odus8cue.cloudfront.net/bbb/bbb_sunflower_1080p_60fps_normal.mp4'
];

// This is just a way of have multiple fallbacks and requires the same number and order than STREAMS_TO_REPLACE
const FALLBACK_STREAMS_MAP = [
  DEFAULT_FALLBACK,
  'https://d3qnhznroa8hr5.cloudfront.net/videos/bbb_multiaudio.mp4',
  DEFAULT_FALLBACK
];

export const SELECTOR_TYPES = {
  subs: 'subs',
  audio: 'audio'
};

const isEpisode = asset => {
  return Boolean(asset?.seasonNumber);
};

const getFallbackStream = fallbackIndex => {
  let fallbackUrl = FALLBACK_STREAMS_MAP[fallbackIndex];
  if (!fallbackUrl) {
    fallbackUrl = DEFAULT_FALLBACK;
  }
  const protocol = window.location.href.split(':')[0];
  return `${protocol}:${fallbackUrl}`;
};

const parseUrl = url => {
  const fallbackIndex = STREAMS_TO_REPLACE.indexOf(url);
  return fallbackIndex >= 0 ? getFallbackStream(fallbackIndex) : url;
};

const getAssetTitle = ({ isTrailer, asset }) => {
  return isTrailer
    ? `${asset?.title} - ${i18n.getTranslation('trailer')}`
    : asset?.title;
};

const getPrevAndNextEpisodes = async ({
  episode,
  episodes,
  seasons,
  segmentationValue
}) => {
  const nextEpisode = await getNextEpisode({
    episode,
    episodes,
    seasons,
    segmentationValue
  });
  const prevEpisode = await getPrevEpisode({
    episode,
    episodes,
    seasons,
    segmentationValue
  });
  const nextEpisodes = await getNextEpisodes({
    episode,
    episodes,
    seasons,
    segmentationValue
  });
  const prevEpisodes = await getPrevEpisodes({
    episode,
    episodes,
    seasons,
    segmentationValue
  });
  const nextEpisodeAsset = nextEpisode
    ? { ...nextEpisode, episodes: nextEpisodes, seasons }
    : undefined;
  const prevEpisodeAsset = prevEpisode
    ? { ...prevEpisode, episodes: prevEpisodes, seasons }
    : undefined;
  return { nextEpisodeAsset, prevEpisodeAsset };
};

/**
 * This methods returns the needed configuration for the playback
 * DRM config, external subtitles and external multidauido
 * @returns {Object} - The options object
 */
const loadOptions = {
  // TODO to be modified in custom project
  playbackOptions: {
    videoHtmlAttributes: {
      crossorigin: 'anonymous'
    },
    // drm: "playready", // the DRM type,
    // drmURL: "https://licenseserver.playready.com", // the license server url
    subtitle: [
      {
        id: 'en_en',
        mimetype: 'webvtt',
        url: '//d3qnhznroa8hr5.cloudfront.net/videos/subs/sintel-en.vtt',
        language: 'en',
        label: 'English'
      },
      {
        id: 'es_es',
        mimetype: 'webvtt',
        url: '//d3qnhznroa8hr5.cloudfront.net/videos/subs/sintel-es.vtt',
        language: 'es',
        label: 'Spanish'
      }
    ]
    // audioTrack: {} // audio track options. This does not modify the audio tracks of the video
  }
};

const getMetadata = ({ asset, rtl }) => {
  let metadata = '';
  if (isEpisode(asset)) {
    const episodeMetadataSeason = i18n.getTranslation(
      'seasonWithNumber',
      asset
    );
    const episodeMetadataEpisode = i18n.getTranslation(
      'episodeWithNumber',
      asset
    );
    const episodeTitle = asset?.episodeTitle;
    metadata = rtl
      ? `${episodeTitle} | ${episodeMetadataEpisode} | ${episodeMetadataSeason}`
      : `${episodeMetadataSeason} | ${episodeMetadataEpisode} | ${episodeTitle}`;
  }
  return metadata;
};

const getAnalyticsData = ({ asset, isTrailer = false }) => {
  if (!asset) {
    return {};
  }
  const analyticsAsset = AnalyticsAsset(asset);
  const title = getAssetTitle({ asset, isTrailer });
  const { videoUrl: streamUrl } = asset || {};
  return {
    ...analyticsAsset,
    isTrailer,
    title,
    fullScreen: true,
    streamUrl
  };
};

const shouldShowPreviousButton = ({ asset, isTrailer, prevEpisode }) => {
  return Boolean(asset?.seasonNumber && prevEpisode && !isTrailer);
};

const shouldShowNextButton = ({ asset, isTrailer, nextEpisode }) => {
  return Boolean(asset?.seasonNumber && nextEpisode && !isTrailer);
};

const mockMediaInstance = {
  getVideo: () => {},
  getSubtitles: () => Promise.resolve([]),
  getAudioTracks: () => Promise.resolve([]),
  getCurrentSubtitle: () => Promise.resolve([]),
  getCurrentAudioTrack: () => Promise.resolve([]),
  setVideoSizeAndPosition: () => Promise.resolve()
};

const getNewPosition = ({ progress, delta, duration }) => {
  return Math.min(Math.max(progress + delta, 0), duration);
};

const stripVideoAdaptationSets = audioOnlyMode => (type, response) => {
  if (type === HttpRequestType.MANIFEST_DASH && audioOnlyMode.current) {
    const parser = new DOMParser();
    const mpd = parser.parseFromString(response.body, 'application/xml');
    const periods = mpd.getElementsByTagName('Period');

    for (let i = 0; i < periods.length; i += 1) {
      const adaptationSets = periods[i].getElementsByTagName('AdaptationSet');

      for (let j = 0; j < adaptationSets.length; j += 1) {
        const representation = adaptationSets[j].getElementsByTagName(
          'Representation'
        );

        if (
          representation[0]?.getAttribute('mimeType') === 'video/mp4' ||
          representation[0]?.getAttribute('height') != null
        ) {
          adaptationSets[j].remove();
          break;
        }
      }
    }

    response.body = new XMLSerializer().serializeToString(mpd);
  }

  return response;
};

export {
  getAnalyticsData,
  getAssetTitle,
  getMetadata,
  getNewPosition,
  getPrevAndNextEpisodes,
  loadOptions,
  mockMediaInstance,
  parseUrl,
  shouldShowPreviousButton,
  shouldShowNextButton,
  stripVideoAdaptationSets
};
