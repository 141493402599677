import { useCallback, useEffect } from 'react';
import { focusManager } from '@accedo/vdkweb-navigation';
import {
  getEcoTipsList,
  getLearnMoreModalEntry
} from '#/providers/cms/control/control';
import useAppConfig from './useAppConfig';

const LEARN_MORE_BUTTON_NAV = {
  id: 'learn-more-button'
};

const POP_UP_MODAL_NAV = {
  leftToggle: {
    id: 'pop-up-left-toggle-button',
    nextright: null,
    nextdown: null
  },
  rightToggle: {
    id: 'pop-up-right-toggle-button',
    nextleft: null,
    nextdown: null
  },
  close: {
    id: 'pop-up-close-button',
    nextright: null,
    nextleft: null,
    nextup: null
  },
  action: {
    id: 'pop-up-action-button',
    nextleft: null,
    nextright: null,
    nextup: null
  }
};

POP_UP_MODAL_NAV.leftToggle.nextright = POP_UP_MODAL_NAV.rightToggle.id;
POP_UP_MODAL_NAV.leftToggle.nextdown = POP_UP_MODAL_NAV.close.id;
POP_UP_MODAL_NAV.rightToggle.nextleft = POP_UP_MODAL_NAV.leftToggle.id;
POP_UP_MODAL_NAV.rightToggle.nextdown = POP_UP_MODAL_NAV.action.id;

POP_UP_MODAL_NAV.close.nextright = POP_UP_MODAL_NAV.action.id;
POP_UP_MODAL_NAV.close.nextleft = POP_UP_MODAL_NAV.leftToggle.id;
POP_UP_MODAL_NAV.close.nextup = POP_UP_MODAL_NAV.leftToggle.id;
POP_UP_MODAL_NAV.action.nextleft = POP_UP_MODAL_NAV.close.id;
POP_UP_MODAL_NAV.action.nextright = POP_UP_MODAL_NAV.rightToggle.id;
POP_UP_MODAL_NAV.action.nextup = POP_UP_MODAL_NAV.rightToggle.id;

let defaultEcoModalData = {
  title: 'Learn More',
  instructions: `Scan the QR code here to explore \n more about the Ecoflow project`,
  findOutMore: `Find out more about sustainability and video streaming at\n ecoflow.tv/sustainablestreaming`,
  qrCode: null
};

const defaultPopUpModalData = {
  title: `🌱 Eco-Tips!`,
  bodyText:
    'Did you know, watching videos in a darker room can reduce the TVs brightness and help reduce carbon emissions.',
  actionButtonText: 'Learn More'
};

const audioOnlyPopUpModalData = {
  title: `Audio-Only Mode`,
  bodyText:
    'Press any arrow button on your remote to show the "Return to Video" option',
  hideActionButtons: true
};

const learnMoreModal = {
  toggle: null,
  setData: null,
  isVisible: false
};

const popUpModal = {
  toggle: null,
  setData: null,
  isVisible: false,
  navIdToRestoreFocusToRef: null
};

const originalNav = {
  id: null
};

const ecoTips = {
  list: null
};

const useEcoModal = ({
  toggleLearnMoreModal,
  setEcoModalData,
  togglePopUp,
  setPopUpModalData,
  navIdToRestoreFocusToRef
} = {}) => {
  const config = useAppConfig();

  useEffect(() => {
    if (!ecoTips.list) {
      ecoTips.list = [];

      const getEcoTips = async () => {
        ecoTips.list = await getEcoTipsList({
          ecoTipsListEntryId: config.config.application.ecoTipsList
        });

        ecoTips.list = ecoTips.list.map((ecoTip, index, ecoTipsList) => ({
          ...ecoTip,
          index,
          total: ecoTipsList.length
        }));
      };

      getEcoTips();
    }
  }, []);

  useEffect(() => {
    if (!defaultEcoModalData.qrCode) {
      defaultEcoModalData.qrCode = {};

      const getLearnMoreModalDefaultData = async () => {
        const learnMoreModalEntry = await getLearnMoreModalEntry({
          learnMoreModalEntryId: '66a99e9423eec6001723a67c'
        });

        defaultEcoModalData = {
          ...learnMoreModalEntry
        };
      };

      getLearnMoreModalDefaultData();
    }
  }, []);

  if (!learnMoreModal.toggle && toggleLearnMoreModal) {
    learnMoreModal.toggle = toggleLearnMoreModal;
  }

  if (!learnMoreModal.setData && setEcoModalData) {
    learnMoreModal.setData = setEcoModalData;
  }

  if (!popUpModal.toggle && togglePopUp) {
    popUpModal.toggle = togglePopUp;
  }

  if (!popUpModal.setData && setPopUpModalData) {
    popUpModal.setData = setPopUpModalData;
  }

  if (!popUpModal.navIdToRestoreFocusToRef && navIdToRestoreFocusToRef) {
    popUpModal.navIdToRestoreFocusToRef = navIdToRestoreFocusToRef;
  }

  const showModalOnClick = useCallback((fromNavId, modalData) => {
    originalNav.id = fromNavId;
    learnMoreModal.setData(modalData || defaultEcoModalData);
    learnMoreModal.toggle(true);
    popUpModal.toggle(false);
    focusManager.changeFocus(LEARN_MORE_BUTTON_NAV.id);
  }, []);

  const hideModalOnClick = useCallback(() => {
    learnMoreModal.toggle(false);
    focusManager.changeFocus(originalNav.id);
    originalNav.id = null;
    learnMoreModal.setData(defaultEcoModalData);
  }, []);

  const showPopUpOnClick = useCallback((fromNavId, popUpData) => {
    if (learnMoreModal.isVisible) {
      return;
    }

    const { learnMoreModalData, ...ecoTipData } = popUpData;
    originalNav.id = fromNavId;

    popUpModal.setData(ecoTipData || defaultPopUpModalData);
    popUpModal.toggle(true);
    focusManager.changeFocus(POP_UP_MODAL_NAV.action.id);

    if (learnMoreModalData && Object.keys(learnMoreModalData).length > 0) {
      learnMoreModal.setData(learnMoreModalData);
    }
  }, []);

  const hidePopUpOnClick = useCallback(showLearnMoreModal => {
    popUpModal.toggle(false);
    popUpModal.setData(defaultPopUpModalData);

    if (popUpModal.navIdToRestoreFocusToRef) {
      popUpModal.navIdToRestoreFocusToRef.current = null;
    }

    if (showLearnMoreModal) {
      learnMoreModal.toggle(true);
      focusManager.changeFocus(LEARN_MORE_BUTTON_NAV.id);
    } else {
      focusManager.changeFocus(originalNav.id);
      originalNav.id = null;
    }
  }, []);

  const showPopUpOnAudioOnly = useCallback(() => {
    popUpModal.setData(audioOnlyPopUpModalData);
    popUpModal.toggle(true);
  }, []);

  const hidePopUpOnAudioOnly = useCallback(() => {
    popUpModal.toggle(false);
    popUpModal.setData(defaultPopUpModalData);
  }, []);

  const setIsLearnMoreModalVisible = useCallback(isVisible => {
    learnMoreModal.isVisible = isVisible;
  }, []);

  const setIsPopUpModalVisible = useCallback(isVisible => {
    popUpModal.isVisible = isVisible;
  }, []);

  const isLearnMoreModalVisible = useCallback(() => {
    return learnMoreModal.isVisible;
  }, []);

  const isPopUpModalVisible = useCallback(() => {
    return popUpModal.isVisible;
  }, []);

  const getEcoTips = useCallback(() => {
    return ecoTips.list || [];
  }, []);

  const getRandomEcoTip = useCallback(() => {
    const minCeiled = Math.ceil(0);
    const maxFloored = Math.floor(ecoTips.list.length);
    const randomIndex = Math.floor(
      Math.random() * (maxFloored - minCeiled) + minCeiled
    );

    return ecoTips.list[randomIndex];
  }, []);

  const getNextEcoTip = useCallback(currentTipIndex => {
    const nextTip =
      currentTipIndex === ecoTips.list.length - 1
        ? ecoTips.list[0]
        : ecoTips.list[currentTipIndex + 1];

    popUpModal.setData(nextTip);
  }, []);

  const getPreviousEcoTip = useCallback(currentTipIndex => {
    const previousTip =
      currentTipIndex === 0
        ? ecoTips.list[ecoTips.list.length - 1]
        : ecoTips.list[currentTipIndex - 1];

    popUpModal.setData(previousTip);
  }, []);

  const getEcoTipsIdleTimeoutDelay = useCallback(() => {
    return config.config.application.ecoTipIdleTimeout;
  }, []);

  const getEcoTipRotationTimeoutDelay = useCallback(() => {
    return config.config.application.ecoTipRotationTimeout;
  }, []);

  const getAudioOnlyPopUpTimeoutDelay = useCallback(() => {
    return config.config.application.audioOnlyPopUpTimeout;
  }, []);

  return {
    showModalOnClick,
    hideModalOnClick,
    showPopUpOnClick,
    hidePopUpOnClick,
    showPopUpOnAudioOnly,
    hidePopUpOnAudioOnly,
    isLearnMoreModalVisible,
    setIsLearnMoreModalVisible,
    isPopUpModalVisible,
    setIsPopUpModalVisible,
    getEcoTips,
    getRandomEcoTip,
    getNextEcoTip,
    getPreviousEcoTip,
    getEcoTipsIdleTimeoutDelay,
    getAudioOnlyPopUpTimeoutDelay,
    getEcoTipRotationTimeoutDelay,
    LEARN_MORE_BUTTON_NAV,
    POP_UP_MODAL_NAV
  };
};

export default useEcoModal;
