import React, {
  useState,
  createContext,
  useMemo,
  useCallback,
  ReactNode
} from 'react';
// import {
//   get as getFromDeviceStorage,
//   set as setIntoDeviceStorage,
//   clear as clearFromDeviceStorage
// } from '#/services/deviceStorage';
import {
  EcoProfile,
  EcoProfileTypeKeys,
  DefaultEcoProfiles
} from '#/interfaces/EcoProfile';
import useStateRef from '#/hooks/useStateRef';
// import { DeviceStorageKey } from '#/interfaces/DeviceStorage';

export interface EcoProfileContextValues {
  onboardingDone: boolean;
  trailerPlaying: boolean;
  trailerUrl: string;
  trailerUrlRef: React.MutableRefObject<string> | null;
  ecoProfileOptionsModalOpen: boolean;
  smallEcoProfileOptionsModalOpen: boolean;
  ecoProfileOptionsModalOpenRef: React.MutableRefObject<boolean> | null;
  smallEcoProfileOptionsModalOpenRef: React.MutableRefObject<boolean> | null;
  currentEcoProfile: EcoProfile | null;
  currentEcoProfileLoading: boolean;
  setEcoProfileOptionsModalOpen: (value: boolean) => void;
  setSmallEcoProfileOptionsModalOpen: (value: boolean) => void;
  setOnboardingDone: () => void;
  setTrailerPlaying: (value: boolean) => void;
  setTrailerUrl: (value: string) => void;
  loadDefaultEcoProfile: () => Promise<void>;
  setCurrentEcoProfile: (profileId: EcoProfileTypeKeys) => void;
  overrideCurrentEcoProfile: (newProfile: Partial<EcoProfile>) => void;
}

export const EcoProfileContext = createContext<EcoProfileContextValues>({
  onboardingDone: false,
  trailerUrl: '',
  trailerUrlRef: null,
  trailerPlaying: false,
  ecoProfileOptionsModalOpen: false,
  smallEcoProfileOptionsModalOpen: false,
  ecoProfileOptionsModalOpenRef: null,
  smallEcoProfileOptionsModalOpenRef: null,
  currentEcoProfile: null,
  currentEcoProfileLoading: false,
  setEcoProfileOptionsModalOpen: (_value: boolean) => {
    throw new Error('Function not implemented.');
  },
  setSmallEcoProfileOptionsModalOpen: (_value: boolean) => {
    throw new Error('Function not implemented.');
  },
  setOnboardingDone: () => {
    throw new Error('Function not implemented.');
  },
  setTrailerPlaying: () => {
    throw new Error('Function not implemented.');
  },
  setTrailerUrl: () => {
    throw new Error('Function not implemented.');
  },
  loadDefaultEcoProfile: () => {
    throw new Error('Function not implemented.');
  },
  setCurrentEcoProfile: () => {
    throw new Error('Function not implemented.');
  },
  overrideCurrentEcoProfile: (newProfile: Partial<EcoProfile>) => {
    throw new Error('Function not implemented.');
  }
});

interface EcoProfileProviderProps {
  children?: ReactNode;
}

const getDefaultProfileId = async (): Promise<EcoProfileTypeKeys> => {
  // TODO: implement some logic to return different value based on the build type
  return 'Measure';
};

const getDefaultEcoProfileConfigById = async (
  ecoProfileId: EcoProfileTypeKeys
) => {
  return DefaultEcoProfiles.get(ecoProfileId);
};

const EcoProfileProvider = ({ children }: EcoProfileProviderProps) => {
  const [onboardingDone, setOnboardingDone] = useState(false);
  const [trailerPlaying, setTrailerPlaying] = useState(false);
  const [trailerUrl, setTrailerUrl, trailerUrlRef] = useStateRef('');
  const [
    ecoProfileOptionsModalOpen,
    setEcoProfileOptionsModalOpen,
    ecoProfileOptionsModalOpenRef
  ] = useStateRef(false);
  const [
    smallEcoProfileOptionsModalOpen,
    setSmallEcoProfileOptionsModalOpen,
    smallEcoProfileOptionsModalOpenRef
  ] = useStateRef(false);
  const [
    currentEcoProfile,
    setCurrentEcoProfile,
    currentEcoProfileRef
  ] = useStateRef<EcoProfile | null>(null);
  const [currentEcoProfileLoading, setCurrentEcoProfileLoading] = useState(
    true
  );

  const handleSetCurrentEcoProfile = useCallback(
    async (profileId: EcoProfileTypeKeys) => {
      const profile = await getDefaultEcoProfileConfigById(profileId);

      if (profile) {
        setCurrentEcoProfile(profile);
      } else {
        console.error('Cannot load eco profile properly');
      }

      setCurrentEcoProfileLoading(false);
    },
    []
  );

  const loadDefaultEcoProfile = useCallback(async () => {
    const profileId = await getDefaultProfileId();
    handleSetCurrentEcoProfile(profileId);
  }, []);

  const overrideCurrentEcoProfile = useCallback(
    async (newProfile: Partial<EcoProfile>) => {
      if (currentEcoProfileRef.current) {
        setCurrentEcoProfile({
          ...(currentEcoProfileRef.current as EcoProfile),
          ...newProfile
        });
      } else {
        console.error('EcoProfile not initialized yet!');
      }
    },
    []
  );

  const setOnboardingDoneCB = useCallback(() => {
    setOnboardingDone(true);
  }, []);

  const setTrailerPlayingCB = useCallback((value: boolean) => {
    setTrailerPlaying(value);
  }, []);

  const setTrailerUrlCB = useCallback((value: string) => {
    setTrailerUrl(value);
  }, []);

  const contextValue = useMemo<EcoProfileContextValues>(
    () => ({
      trailerUrl,
      trailerUrlRef,
      trailerPlaying,
      onboardingDone,
      currentEcoProfile,
      currentEcoProfileLoading,
      ecoProfileOptionsModalOpen,
      smallEcoProfileOptionsModalOpen,
      ecoProfileOptionsModalOpenRef,
      smallEcoProfileOptionsModalOpenRef,
      setEcoProfileOptionsModalOpen,
      setSmallEcoProfileOptionsModalOpen,
      setOnboardingDone: setOnboardingDoneCB,
      setTrailerPlaying: setTrailerPlayingCB,
      setTrailerUrl: setTrailerUrlCB,
      loadDefaultEcoProfile,
      setCurrentEcoProfile: handleSetCurrentEcoProfile,
      overrideCurrentEcoProfile
    }),
    [
      trailerUrl,
      trailerPlaying,
      onboardingDone,
      currentEcoProfile,
      currentEcoProfileLoading,
      loadDefaultEcoProfile,
      handleSetCurrentEcoProfile,
      overrideCurrentEcoProfile,
      ecoProfileOptionsModalOpen,
      smallEcoProfileOptionsModalOpen
    ]
  );

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <EcoProfileContext.Provider value={contextValue}>
      {children}
    </EcoProfileContext.Provider>
  );
};

export default EcoProfileProvider;
