import React, { useState } from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import useEcoModal from '#/hooks/useEcoModal';
import useEcoProfile from '#/hooks/useEcoProfile';
import style from './learnMoreModal.scss';
import { FocusRoundButton } from '../RoundButton/RoundButton';
import Modal from '../Modal/Modal';

const LearnMoreModal = () => {
  const { currentEcoProfile } = useEcoProfile();
  const [showLearnMoreModal, toggleLearnMoreModal] = useState(false);
  const [ecoModalData, setEcoModalData] = useState({});
  const {
    LEARN_MORE_BUTTON_NAV,
    hideModalOnClick,
    setIsLearnMoreModalVisible
  } = useEcoModal({
    toggleLearnMoreModal,
    setEcoModalData
  });
  const { title, instructions, findOutMore, qrCode } = ecoModalData;

  setIsLearnMoreModalVisible(showLearnMoreModal);

  return (
    showLearnMoreModal && (
      <Modal>
        <div className={style.background}>
          <div
            className={classNames(style.container, {
              [style.containerLightTheme]: !currentEcoProfile?.darkUxEnabled
            })}
          >
            <div className={style.title}>{title}</div>
            <div className={style.instructions}>{instructions}</div>
            <div className={style.qrCode}>
              <img src={qrCode?.fileUrl} alt="QR Code" />
            </div>
            <div className={style.findOutMore}>{findOutMore}</div>
            <FocusRoundButton
              displayText="Done"
              className={style.doneButton}
              nav={LEARN_MORE_BUTTON_NAV}
              onClick={hideModalOnClick}
            />
          </div>
        </div>
      </Modal>
    )
  );
};

export default withFocus(LearnMoreModal);
