/*
 * Managing the routes for the application views.
 * Register new routes if introducing new views.
 */
import React from 'react';

import ModularPage from '#/views/ModularPage/ModularPage';

import { ANALYTICS_SCREEN_TYPES, PAGE_TEMPLATES } from '#/config/constants';
import { getRouteInfoFromPageLayout } from '#/utils/routesHelper';

import ViewDoesNotExistInTheApp from '#/views/ViewDoesNotExistInTheApp/ViewDoesNotExistInTheApp';
import NetworkError from '#/views/NetworkError/NetworkError';
import PageLayout from '#/containers/PageLayout/PageLayout';
import Loader from '#/components/Loader/Loader';

const About = React.lazy(() => import('../views/About/About'));
const CategoryListing = React.lazy(() =>
  import('../views/Listing/CategoryListing')
);

const NetworkErrorFn = () => {
  return { default: NetworkError };
};

const Detail = React.lazy(() =>
  import('../views/Detail/Detail').catch(NetworkErrorFn)
);
const CollectionPage = React.lazy(() =>
  import('../views/CollectionPage/CollectionPage').catch(NetworkErrorFn)
);
const Search = React.lazy(() =>
  import('../views/Search/Search').catch(NetworkErrorFn)
);
const ViewAll = React.lazy(() =>
  import('../views/ViewAll/ViewAll').catch(NetworkErrorFn)
);
const Login = React.lazy(() =>
  import('../views/Login/Login').catch(NetworkErrorFn)
);
const IdInput = React.lazy(() =>
  import('../views/IdInput/IdInput').catch(NetworkErrorFn)
);
const EPGPage = React.lazy(() =>
  import('../views/EPGPage/EPGPage').catch(NetworkErrorFn)
);
const MyContent = React.lazy(() =>
  import('../views/MyContent/MyContent').catch(NetworkErrorFn)
);
const EcoSettings = React.lazy(() =>
  import('../views/EcoSettings/EcoSettings').catch(NetworkErrorFn)
);
const PairingCode = React.lazy(() =>
  import('../views/PairingCode/PairingCode').catch(NetworkErrorFn)
);
const Player = React.lazy(() =>
  import('../views/Player/Player').catch(NetworkErrorFn)
);
const Profile = React.lazy(() =>
  import('../views/Profile/Profile').catch(NetworkErrorFn)
);

const ProgramDetailPage = React.lazy(() =>
  /* webpackChunkName: "ProgramDetailPage" */ import(
    '../views/ProgramDetail/ProgramDetailPage'
  ).catch(NetworkErrorFn)
);
const Profiles = React.lazy(() =>
  import('../views/Profiles/Profiles').catch(NetworkErrorFn)
);
const Onboarding = React.lazy(() =>
  import('../views/Onboarding/Onboarding').catch(NetworkErrorFn)
);

/*
 * Routes Definition in 4 blocks:
 * 1. pageLayoutMapPages: are Dynamic Pages that requires pageLayout from CMS
 * 2. baseRoutes: are harcoded routes in the app
 * 3. fallbackRoutes: are harcoded routes that defines the fallback behaviour of the app routing system
 * 4. local nested routes: subroutes defined locally, that is, within the view component itself.
 *    they are hard coded and depends on an unique Page defined at the pageLayoutMapPages.
 *    e.g.: Profiles is a page and has it's hard coded nested routes defined at the view.
 *    you should opt to use this option just in case you want to have an entire feature configuration
 *    (theme, route, etc) managed by an unique Page. i.e. theme customization is shared between all
 *    nested routes inside Profiles.
 */

/*
 * pageLayoutMapPages DataModel:
 * - template: the template to map against from the CMS
 * - hasSubroute: whether it's a subroute or not
 * - suspense: whether it has to use React.Suspense
 * - analyticsScreenType: value of the analytics screenType for the route
 * - analyticsOnPageLayoutLoad: whether the page should report the screenView directly when pageLayout is loaded
 * - view: associted View Component
 */
const pageLayoutMapPages = [
  {
    template: PAGE_TEMPLATES.modular,
    hasSubroute: false,
    suspense: false,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.page,
    analyticsOnPageLayoutLoad: true,
    view: ModularPage
  },
  {
    template: PAGE_TEMPLATES.collection,
    hasSubroute: true,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.collection,
    analyticsOnPageLayoutLoad: true,
    view: CollectionPage
  },
  {
    template: PAGE_TEMPLATES.myContent,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.myContent,
    analyticsOnPageLayoutLoad: true,
    view: MyContent
  },
  {
    template: PAGE_TEMPLATES.epg,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.epg,
    analyticsOnPageLayoutLoad: true,
    view: EPGPage
  },
  {
    template: PAGE_TEMPLATES.profile,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.profile,
    analyticsOnPageLayoutLoad: true,
    view: Profile
  },
  {
    template: PAGE_TEMPLATES.search,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.search,
    analyticsOnPageLayoutLoad: true,
    view: Search
  },
  {
    template: PAGE_TEMPLATES.signin,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.login,
    analyticsOnPageLayoutLoad: true,
    view: Login
  },
  {
    template: PAGE_TEMPLATES.info,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.settings,
    analyticsOnPageLayoutLoad: true,
    view: EcoSettings
  },
  {
    template: PAGE_TEMPLATES.category,
    hasSubroute: true,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.category,
    analyticsOnPageLayoutLoad: true,
    view: CategoryListing
  },
  {
    template: PAGE_TEMPLATES.pairing,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.pairing,
    analyticsOnPageLayoutLoad: true,
    view: PairingCode
  },
  {
    template: PAGE_TEMPLATES.viewAll,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.viewAll,
    analyticsOnPageLayoutLoad: true,
    view: ViewAll
  },
  {
    template: PAGE_TEMPLATES.showDetail,
    hasSubroute: true,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.details,
    analyticsOnPageLayoutLoad: false,
    view: Detail
  },
  {
    template: PAGE_TEMPLATES.movieDetail,
    hasSubroute: true,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.details,
    analyticsOnPageLayoutLoad: false,
    view: Detail
  },
  {
    template: PAGE_TEMPLATES.programDetail,
    hasSubroute: true,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.details,
    analyticsOnPageLayoutLoad: false,
    view: ProgramDetailPage
  },
  {
    template: PAGE_TEMPLATES.player,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.player,
    analyticsOnPageLayoutLoad: false,
    view: Player
  },
  {
    template: PAGE_TEMPLATES.profiles,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.profiles,
    analyticsOnPageLayoutLoad: false,
    view: Profiles
  },
  {
    template: PAGE_TEMPLATES.onboarding,
    hasSubroute: false,
    suspense: true,
    analyticsScreenType: ANALYTICS_SCREEN_TYPES.onboarding,
    analyticsOnPageLayoutLoad: false,
    view: Onboarding
  }
];

const baseRoutes = [
  {
    path: '/about',
    element: (
      <React.Suspense fallback={<>...</>}>
        <About />
      </React.Suspense>
    )
  },
  {
    path: '/Idinput',
    element: (
      <React.Suspense fallback={<>...</>}>
        <IdInput />
      </React.Suspense>
    )
  },
  {
    path: '/network-error',
    element: <NetworkError />
  }
];

const fallbackRoutes = [
  {
    path: '*',
    element: (
      <React.Suspense fallback={<>...</>}>
        <ViewDoesNotExistInTheApp />
      </React.Suspense>
    )
  }
];

const pageLayoutContainer = ({
  view,
  screenType,
  analyticsOnPageLayoutLoad
}) => (
  <PageLayout
    View={view}
    screenType={screenType}
    analyticsOnPageLayoutLoad={analyticsOnPageLayoutLoad}
  />
);

const pageLayoutContainerWithSuspense = ({ innerElement }) => {
  return <React.Suspense fallback={<Loader />}>{innerElement}</React.Suspense>;
};

const routes = routesMapping => {
  const dynamicRoutes = routesMapping.map(routeItem => {
    const itemPageLayout = pageLayoutMapPages.find(
      pageLayoutItem => pageLayoutItem.template === routeItem.template
    );

    if (itemPageLayout) {
      return getRouteInfoFromPageLayout({
        itemPageLayout,
        routeItem,
        pageLayoutContainerWithSuspense,
        pageLayoutContainer
      });
    }
    console.error(
      `It's not possible to map the route "${routeItem.route}" from Accedo Control, because this view still doesn't exist in Elevate.`
    );

    return {
      path: routeItem.route,
      element: <ViewDoesNotExistInTheApp />
    };
  });
  const allRoutes = [...baseRoutes, ...dynamicRoutes, ...fallbackRoutes];
  return allRoutes;
};

export default routes;
