import {
  getApplicationConfiguration,
  getProvidersConfiguration
} from '#/services/config';

import { DEFAULT_LOCALE } from '#/config/constants';

export const getDictionaryEntryId = async segmentationValue => {
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const {
    i18n: {
      accedoControl: { dictionary: providersDictionaryEntryId } = {}
    } = {}
  } = providersConfiguration;
  return providersDictionaryEntryId;
};

export const getDefaultLocale = async segmentationValue => {
  const applicationConfiguration = await getApplicationConfiguration(
    segmentationValue
  );
  const {
    i18n: { defaultLocale = DEFAULT_LOCALE } = {}
  } = applicationConfiguration;
  return defaultLocale || DEFAULT_LOCALE;
};

export const getMenuId = async segmentationValue => {
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const {
    cms: { accedoControl: { menu: mainMenuEntryId } = {} } = {}
  } = providersConfiguration;
  return mainMenuEntryId;
};
export const getRoutesMappingFromConfig = async segmentationValue => {
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const {
    cms: { accedoControl: { routesToPagesMappingList } = {} } = {}
  } = providersConfiguration;
  return routesToPagesMappingList;
};

export const getPlaybackConfig = async () => {
  const applicationConfiguration = await getApplicationConfiguration();
  const { player } = applicationConfiguration;
  return player;
};

export const getDefaultTheme = async segmentationValue => {
  const applicationConfiguration = await getApplicationConfiguration(
    segmentationValue
  );
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const {
    theme: { accedoControl: accedoControlThemeProviderConfig = {} }
  } = providersConfiguration;
  const {
    theme: { default: defaultThemeKey }
  } = applicationConfiguration;
  return accedoControlThemeProviderConfig[defaultThemeKey];
};

export const getDarkTheme = async segmentationValue => {
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const {
    theme: { accedoControl: accedoControlThemeProviderConfig = {} }
  } = providersConfiguration;
  return accedoControlThemeProviderConfig.kids;
};

export const getOVPConfig = async segmentationValue => {
  const providersConfiguration = await getProvidersConfiguration(
    segmentationValue
  );
  const { ovp: { accedoOvp = {} } = {} } = providersConfiguration || {};
  return accedoOvp;
};
