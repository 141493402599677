import { useCallback, useRef, useState } from 'react';

/**
 * Hook that combines useState + useRef for cases in which you need
 * to update the screen but also be able to access the updated value
 * right away in other hooks like useState, useMemo, or useCallback.
 *
 * Calling the setState function will update the ref as well but changing
 * the ref won't update the state. Be careful as the intended use is to
 * do updates using the setState function.
 *
 * WARNING: The ref shoud not be used in HTML elements.
 */

type UpdateState<T> = (prevState: T) => T;

const useStateRef = <T>(initialState: T) => {
  const [state, setState] = useState<T>(initialState);
  const ref = useRef<T>(initialState);

  const updateState = useCallback((newState: React.SetStateAction<T>) => {
    if (typeof newState === 'function') {
      setState(previous => {
        const updated = (newState as UpdateState<T>)(previous);

        ref.current = updated;

        return updated;
      });

      return;
    }

    ref.current = newState;
    setState(newState);
  }, []);

  // Set ref as readonly to prevent accidental misuse of the hook.
  // Use 'as const' so the returned array is a proper tuple.
  return [state, updateState, ref as { readonly current: T }] as const;
};

export default useStateRef;
