import React, { useState, useEffect, memo, useCallback } from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import Nav from '#/interfaces/Nav';
import { getTranslation } from '#/services/i18n';
import useAppConfig from '#/hooks/useAppConfig';
import useEcoProfile from '#/hooks/useEcoProfile';
import LightThemeLogo from '#/static/images/ecoflow-logo-light.png';
import EcoStreamerBadge from '#/static/images/eco-streamer-badge.png';
import EcoBadgesBatch from '#/static/images/eco-badges-batch.png';
import { ShareIcon } from '#/components/Icons/Icons';

import style from './infoItem.scss';

interface InfoItem {
  data: any; // "any" until we review the data structure.
  nav: Nav;
  onClick: () => any | void;
  buttonText: string;
  width: number | string;
  height: number | string;
  isFocused: boolean;
}

const getSkew = (value: number, quartile: number) => {
  const excess = value - 25 * quartile;

  if (value >= 100) {
    return 0;
  }

  if (value < 25 * (quartile - 1)) {
    // Empty
    return 90;
  }

  if (excess >= 0) {
    // Full
    return 0;
  }

  return (Math.abs(excess) * 90) / 25;
};

const InfoItem = (infoItemProps: InfoItem) => {
  const {
    nav,
    data,
    buttonText: buttonTextProp,
    width: widthProp,
    height: heightProp
  } = infoItemProps;
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);
  const [buttonText, setButtonText] = useState(buttonTextProp);
  const [poster, setPoster] = useState('');
  const { config } = useAppConfig();
  const [currentLogo, setCurrentLogo] = useState(config.appLogo);
  const [ecoFeaturesUsage, setEcoFeaturesUsage] = useState(null);
  const { currentEcoProfile } = useEcoProfile();

  const loadImage = useCallback(() => {
    let image = '';

    if (data.bannerImage?.fileUrl) {
      image = data.bannerImage.fileUrl;
    }

    if (!currentEcoProfile?.darkUxEnabled && data.bannerImageLight?.fileUrl) {
      image = data.bannerImageLight.fileUrl;
    }

    setPoster(image);
  }, [data.bannerImage, data.bannerImageLight, currentEcoProfile]);

  const loadButtonTranslation = useCallback(async () => {
    if (buttonText) {
      return;
    }

    const translation = getTranslation('moreDetails');
    setButtonText(translation);
  }, [buttonText]);

  useEffect(() => {
    loadImage();
    loadButtonTranslation();
  }, [loadButtonTranslation, loadImage]);

  useEffect(() => {
    if (currentEcoProfile && config) {
      if (currentEcoProfile.darkUxEnabled) {
        setCurrentLogo(config.appLogo);
      } else {
        setCurrentLogo(LightThemeLogo);
      }
    }
  }, [currentEcoProfile, config]);

  useEffect(() => {
    if (data && data.ecoFeatureUsageList) {
      const features = data.ecoFeatureUsageList.map((usage: any) => {
        let level = 'Low';

        if (
          usage.measurementValue > usage.moderateThreshold &&
          usage.measurementValue < usage.excellentThreshold
        ) {
          level = 'Moderate';
        } else if (usage.measurementValue >= usage.excellentThreshold) {
          level = 'Excellent';
        }

        return (
          <div className={style.dataItem}>
            <div className={style.usageCircularBar}>
              {getSkew(usage.measurementValue, 1) !== 90 && (
                <div
                  className={style.firstQuartile}
                  style={{
                    transform: `rotate(0deg) skew(${getSkew(
                      usage.measurementValue,
                      1
                    )}deg)`
                  }}
                />
              )}
              {getSkew(usage.measurementValue, 2) !== 90 && (
                <div
                  className={style.secondQuartile}
                  style={{
                    transform: `rotate(90deg) skew(${getSkew(
                      usage.measurementValue,
                      2
                    )}deg)`
                  }}
                />
              )}
              {getSkew(usage.measurementValue, 3) !== 90 && (
                <div
                  className={style.thirdQuartile}
                  style={{
                    transform: `rotate(180deg) skew(${getSkew(
                      usage.measurementValue,
                      3
                    )}deg)`
                  }}
                />
              )}
              {getSkew(usage.measurementValue, 4) !== 90 && (
                <div
                  className={style.fourthQuartile}
                  style={{
                    transform: `rotate(270deg) skew(${getSkew(
                      usage.measurementValue,
                      4
                    )}deg)`
                  }}
                />
              )}
              <div
                className={classNames(style.innerCircle, {
                  [style.innerCircleLightTheme]: !currentEcoProfile?.darkUxEnabled
                })}
              >
                <span className={style.measurementValue}>
                  {usage.measurementValue}
                </span>
                <span className={style.unitOfMeasurement}>
                  {usage.unitOfMeasurement}
                </span>
              </div>
            </div>
            <div className={style.usageCircularBarSubtitle}>{usage.title}</div>
            <div
              className={classNames(style.usageEnergySavingsLabel, {
                [style.labelLightTheme]: !currentEcoProfile?.darkUxEnabled
              })}
            >{`Energy Savings: ${level}`}</div>
            <div className={style.usageEnergySavingsBar}>
              <div
                className={classNames(
                  style.progress,
                  style[level.toLowerCase()]
                )}
              />
            </div>
          </div>
        );
      });

      setEcoFeaturesUsage(features);
    }
  }, [data, data?.ecoFeatureUsageList, currentEcoProfile?.darkUxEnabled]);

  const backgroundClassName = `${style.background} ${
    nav.isFocused ? style.backgroundFocused : ''
  } ${!currentEcoProfile?.darkUxEnabled ? style.backgroundLightTheme : ''}`;

  return (
    <div
      className={style.infoBannerContainer}
      style={{ width, height }}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <div className={backgroundClassName}>
        {poster && (
          <img src={poster} className={style.backgroundImage} alt="info" />
        )}
        <div
          className={classNames(style.contentContainer, {
            [style.dataBanner]: data.ecoFeatureUsageList
          })}
        >
          <div
            className={classNames(style.copyContainer, {
              [style.copyLightTheme]: !currentEcoProfile?.darkUxEnabled
            })}
          >
            {!data.ecoFeatureUsageList && (
              <>
                <img src={currentLogo} className={style.logo} alt="logo" />
                <div className={style.title}>{data.title}</div>
                <div className={style.body}>{data.bodyText}</div>
                <RoundButton
                  className={style.button}
                  displayText="Learn More"
                  nav={nav}
                />
              </>
            )}

            {data.ecoFeatureUsageList && (
              <div
                className={classNames(style.dataContainer, {
                  [style.dataBanner]: data.ecoFeatureUsageList
                })}
              >
                <div
                  className={classNames(style.dataPanel, {
                    [style.dataPanelLightTheme]: !currentEcoProfile?.darkUxEnabled
                  })}
                >
                  <div className={style.dataTitle}>{data.dataTitle}</div>
                  <div className={style.dataSubtitle}>{data.dataSubtitle}</div>
                  <div className={style.dataItemsContainer}>
                    {ecoFeaturesUsage}
                  </div>
                </div>
              </div>
            )}

            {data.ecoFeatureUsageList && (
              <div className={style.achievementsContainer}>
                <div
                  className={classNames(style.achievementsPanel, {
                    [style.lightTheme]: !currentEcoProfile?.darkUxEnabled
                  })}
                >
                  <div className={style.achievementsTitle}>
                    Your Achievements
                  </div>
                  <div
                    className={classNames(style.latestAchievementsContainer, {
                      [style.lightTheme]: !currentEcoProfile?.darkUxEnabled
                    })}
                  >
                    <img
                      className={style.badge}
                      src={EcoStreamerBadge}
                      alt="Eco Streamer Badge"
                    />
                    <div className={style.badgeDescription}>
                      Eco <br /> Streamer <span>30%+ Eco Mode</span>
                    </div>
                    <div className={style.reward}>
                      <span>🎉</span> You have earned 7 days of Ecoflow Premium
                    </div>
                  </div>
                  <div className={style.moreAchievements}>
                    <img
                      className={style.badgesBatch}
                      src={EcoBadgesBatch}
                      alt="Badges Batch"
                    />
                    <span>+8 more to earns</span>
                  </div>
                </div>
                <div className={style.achievementsButtons}>
                  <RoundButton
                    className={style.achievementButton}
                    displayText="Learn More"
                    nav={nav}
                  />
                  <RoundButton
                    className={classNames(
                      style.achievementButton,
                      style.shareButton
                    )}
                    displayText={
                      <div className={style.shareButtonContainer}>
                        <ShareIcon className={style.shareIcon} />
                        <div>Share</div>
                      </div>
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const propsAreEqual = (prevProps: InfoItem, nextProps: InfoItem) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.title === nextData.title &&
    prevNav.id === nextNav.id &&
    prevNav.parent === nextNav.parent
  );
};

export default memo(withFocus(InfoItem), propsAreEqual);
