import * as vikimap from '@accedo/vdkweb-vikimap';

import {
  SESSION_KEY,
  getControlClientSingleton
} from '#/providers/shared/control/control';
import {
  getMenuId,
  getRoutesMappingFromConfig,
  getDefaultLocale,
  getDefaultTheme as getDefaultThemeFromConfig,
  getDarkTheme as getDarkThemeFromConfig
} from '#/providers/shared/control/config';
import fetcher from '#/services/helpers/fetcher';
import { PROVIDER_TYPE } from '#/config/constants';
import { getSegmentationCachePart } from '#/utils/segmentationUtils';

/**
 * @module providers/cms/control
 * @description
 * Provider CMS implementation from Accedo Control
 */
let accedoControlService = null;
let accedoOneClientInstance = null;
let menu = null;
let menuLocaleCode = null;
let segmentInitiated = null;
let menuGid = null;
let initiated = false;

export const init = async segmentationValue => {
  accedoOneClientInstance = await getControlClientSingleton(segmentationValue);

  if (!accedoControlService) {
    accedoControlService = vikimap.getAccedoOneService(accedoOneClientInstance);
  }
  segmentInitiated = segmentationValue;
  initiated = true;
};

/**
 *
 * Fetch the Menu CMS content
 *
 * @param {any} options the Control options as defined in the Control documentation
 * @param {any} options.gid the segmentation value as Control gid
 * @param {any} options.locale Application locale code
 *
 * @returns {Menu} The Menu
 */
const getMenu = async options => {
  const segmentationValue = options?.gid;
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }
  const menuEntryId = await getMenuId(segmentationValue);
  if (menu && options.locale === menuLocaleCode && options.gid === menuGid) {
    return menu;
  }

  const defaultLocale = await getDefaultLocale(segmentationValue);
  menu = await accedoControlService.getMenu(menuEntryId, options);

  menuLocaleCode = options?.locale || defaultLocale;
  menuGid = options?.gid || undefined;
  return menu;
};

const getRoutesFromControl = async segmentationValue => {
  const routesMappingId = await getRoutesMappingFromConfig(segmentationValue);
  const routesMappingParser = async routesMappingResults => {
    routesMappingResults.mappings = await accedoControlService.getItemsByIds(
      routesMappingResults.mappings
    );
    const pageIds = routesMappingResults.mappings.map(map => map.page);
    const pageResponses = await accedoControlService.getItemsByIds(pageIds);
    routesMappingResults.mappings.forEach(mapItem => {
      const pageResponse = pageResponses.find(
        pageResponseItem => pageResponseItem.id === mapItem.page
      );

      mapItem.page = pageResponse;
    });
    return routesMappingResults;
  };
  const [
    { value: routesMappingResponse },
    { value: menuResponse }
  ] = await Promise.allSettled([
    accedoControlService
      .getItem(routesMappingId, { gid: segmentationValue })
      .then(routesMappingResults => routesMappingParser(routesMappingResults)),
    getMenu({ gid: segmentationValue })
  ]);
  if (menuResponse && routesMappingResponse) {
    menuResponse.items.forEach(menuItem => {
      routesMappingResponse.mappings.push(menuItem);
    });
  }
  return routesMappingResponse?.mappings || [];
};

const getRoutes = async segmentationValue => {
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }
  const segmentationCachePart = getSegmentationCachePart(segmentationValue);
  return fetcher({
    cacheId: `${PROVIDER_TYPE.cms}provider-routes${segmentationCachePart}`,
    fetchFn: () => getRoutesFromControl(segmentationValue)
  });
};

const getPageIdFromRoute = async (route, gid) => {
  const splitedRoute = route.split('/');
  const initialRoutePart = `/${splitedRoute[1]}`;

  const routes = await getRoutes(gid);

  const routeForPage = routes.find(
    routeFromControl =>
      routeFromControl.route === initialRoutePart ||
      routeFromControl.route === `${initialRoutePart}/*`
  );

  if (!routeForPage?.page?.id) {
    console.warn(`[debug] unable to find page for the route`);
    throw new Error('unable to find page for the route');
  }
  return routeForPage?.page?.id;
};

/**
 *
 * Fetch the Page CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 * @param {any} options.gid The control gid
 * @param {any} options.locale the Control options as defined in the Control documentation
 *
 * @returns {Page} The page
 */
const getPageLayout = async ({ route, ...options }) => {
  const segmentationValue = options?.gid;
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }
  const pageId = await getPageIdFromRoute(route, segmentationValue);
  return accedoControlService.getPage(pageId, options);
};

const getDefaultTheme = async segmentationValue => {
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }
  const theme = await getDefaultThemeFromConfig(segmentationValue);
  let appTheme;
  if (theme) {
    appTheme = await accedoControlService.getItem(theme);
  }
  return appTheme;
};

const getDarkTheme = async segmentationValue => {
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }
  const theme = await getDarkThemeFromConfig(segmentationValue);
  let appTheme;
  if (theme) {
    appTheme = await accedoControlService.getItem(theme);
  }
  return appTheme;
};

/**
 *
 * Fetch the Page Theme CMS content
 *
 * @param {String} id the Page id
 * @param {any} options the Control options as defined in the Control documentation
 * @param {string} options.locale locale code
 * @param {string} options.gid segmentation value as Control gid
 *
 * @returns {Page} The theme
 */
const getThemeByPage = async ({ route, ...options }) => {
  const segmentationValue = options?.gid;
  if (
    !initiated ||
    (segmentationValue && segmentInitiated !== segmentationValue)
  ) {
    await init(segmentationValue);
  }

  const pageId = await getPageIdFromRoute(route, options.gid);

  return accedoOneClientInstance.getEntryById(pageId, options).then(page => {
    if (!page.theme) {
      return;
    }
    return accedoOneClientInstance.getEntryById(page.theme, options);
  });
};

export const getOnboarding = async ({ onboardingEntryId }) => {
  const onboardingData = await accedoOneClientInstance.getEntryById(
    onboardingEntryId
  );
  const pages = await accedoOneClientInstance.getEntries({
    id: onboardingData.pages
  });
  onboardingData.pages = pages.entries;
  return onboardingData;
};

export const getEcoTipsList = async ({ ecoTipsListEntryId }) => {
  const {
    ecoTipsList: ecoTipsListEntries
  } = await accedoOneClientInstance.getEntryById(ecoTipsListEntryId);

  const { entries: ecoTips } = await accedoOneClientInstance.getEntries({
    id: ecoTipsListEntries
  });

  const {
    entries: learnMoreModalDataList
  } = await accedoOneClientInstance.getEntries({
    id: ecoTips.reduce(
      (learnMoreModalEntriesList, ecoTip) => [
        ...learnMoreModalEntriesList,
        ecoTip.learnMoreModalData
      ],
      []
    )
  });

  ecoTips.forEach((ecoTip, index) => {
    ecoTip.learnMoreModalData = learnMoreModalDataList[index];
  });

  return ecoTips;
};

export const getInfoBanner = async ({ infoBannerEntryId }) => {
  const infoBanner = await accedoOneClientInstance.getEntryById(
    infoBannerEntryId
  );

  const learnMoreModalData = await accedoOneClientInstance.getEntryById(
    infoBanner.learnMoreModalData
  );

  infoBanner.learnMoreModalData = learnMoreModalData;

  if (infoBanner.ecoFeatureUsageList) {
    const ecoFeatureUsageList = await accedoOneClientInstance.getEntryById(
      infoBanner.ecoFeatureUsageList
    );

    const {
      entries: ecoFeatureUsages
    } = await accedoOneClientInstance.getEntries({
      id: ecoFeatureUsageList.ecoFeatureUsages
    });

    infoBanner.dataTitle = ecoFeatureUsageList.title;
    infoBanner.dataSubtitle = ecoFeatureUsageList.subtitle;
    infoBanner.ecoFeatureUsageList = ecoFeatureUsages;
  }

  return [infoBanner];
};

export const getLearnMoreModalEntry = async ({ learnMoreModalEntryId }) => {
  const learnMoreModalData = await accedoOneClientInstance.getEntryById(
    learnMoreModalEntryId
  );

  return learnMoreModalData;
};

export const cleanAccedoOneSessionInClient = async () => {
  const { default: xdk } = await import('@accedo/xdk-core');
  await xdk.load();
  xdk.storage.unset(SESSION_KEY);
};

export default () => {
  return {
    init,
    getMenu,
    getRoutes,
    getPageLayout,
    getDefaultTheme,
    getDarkTheme,
    getThemeByPage
  };
};
