const isSelfHosted = () => {
  return window.location.href.startsWith('file:');
};

const isBase64 = src => {
  return (
    src.indexOf('data:image/png;base64') !== -1 ||
    src.indexOf('data:image/jpeg;base64') !== -1
  );
};

const getProtocolRelativePathUrl = (src = '') => {
  if (typeof src !== 'string') {
    return;
  }

  if (isSelfHosted()) {
    return src;
  }

  if (isBase64(src)) {
    return src;
  }

  const relativePathSeparator = '//';
  const pathSeparatorIndex = src.indexOf(relativePathSeparator);

  if (pathSeparatorIndex > -1) {
    return src.slice(pathSeparatorIndex);
  }
  return src;
};

const getQueryStringParameters = url => {
  if (url.split('?').length > 1) {
    const query = url.split('?')[1];

    return query.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {});
  }
};

export { getProtocolRelativePathUrl, getQueryStringParameters };
