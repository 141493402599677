import { useEffect } from 'react';
import { environment } from '@accedo/xdk-core';

const useKeyHandler = (handler, eventType = environment.SYSTEM.KEYDOWN) => {
  useEffect(() => {
    environment.addEventListener(eventType, handler);

    // Remove event listener on cleanup
    return () => {
      environment.removeEventListener(eventType, handler);
    };
  }, [handler]);
};

export default useKeyHandler;
